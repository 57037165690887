import React, { useContext, useState } from 'react';
import { css } from '@emotion/css';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import styled, { css as styledCss } from 'styled-components';
import AcknowledgeModal from '~/components/Modals/AcknowledgeModal';
import { ThemeContext } from '../../contexts/theme';

type PresetTypes =
  | 'circle'
  | 'square'
  | 'triangle'
  | 'upsideDownTriangle'
  | 'horizontalLine'
  | 'verticalLine'
  | 'circleOutline'
  | 'squareOutline'
  | 'triangleOutline'
  | 'upsideDownTriangleOutline'
  | 'sortByName'
  | 'sortByNameDescending';

const ACTIONS = {
  sortByName: 'Sort Selected A→Z',
  sortByNameDescending: 'Sort Selected Z→A',
};

const SHAPES = {
  circle: 'Circle',
  square: 'Square',
  triangle: 'Triangle 1',
  upsideDownTriangle: 'Triangle 2',
  horizontalLine: 'Horizontal Line',
  verticalLine: 'Vertical Line',
};

const SHAPES2 = {
  circleOutline: 'Circle',
  squareOutline: 'Square',
  triangleOutline: 'Triangle 1',
  upsideDownTriangleOutline: 'Triangle 2',
};

const PresetIcon = styled.div<{
  backgroundColor: string;
  shape: PresetTypes;
}>`
  position: relative;
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;

  ${props => {
    switch (props.shape) {
      case 'sortByName':
        return styledCss`
          &:before {
            content: 'A→Z';
            color: ${props.backgroundColor};
            font-size: 16px;
            position: absolute;
            top: 10px;
            left: 10px;
          }
        `;
      case 'sortByNameDescending':
        return styledCss`
          &:before {
            content: 'Z→A';
            color: ${props.backgroundColor};
            font-size: 16px;
            position: absolute;
            top: 10px;
            left: 10px;
          }
        `;
      case 'circle':
        return styledCss`
          border-radius: 50%;
          background-color: ${props.backgroundColor};
        `;
      case 'square':
        return styledCss`
          background-color: ${props.backgroundColor};
        `;
      case 'triangle':
        return styledCss`
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-bottom: 36px solid ${props.backgroundColor};
        `;
      case 'upsideDownTriangle':
        return styledCss`
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-top: 36px solid ${props.backgroundColor};
        `;
      case 'horizontalLine':
        return styledCss`
          height: 8px;
          background-color: ${props.backgroundColor};
          margin-top: 14px;
          margin-bottom: 14px;
        `;
      case 'verticalLine':
        return styledCss`
          width: 8px;
          background-color: ${props.backgroundColor};
          margin-left: 14px;
        `;
      case 'circleOutline':
        return styledCss`
          border-radius: 50%;
          border: 5px solid ${props.backgroundColor};
        `;
      case 'squareOutline':
        return styledCss`
          border: 5px solid ${props.backgroundColor};
        `;
      case 'triangleOutline':
        return styledCss`
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-bottom: 36px solid ${props.backgroundColor};

          &:before {
            content: "";
            display: block;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 20px solid rgb(38, 38, 38);
            position: absolute;
            top: 11px;
            left: -10px;
          }
        `;
      case 'upsideDownTriangleOutline':
        return styledCss`
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-top: 36px solid ${props.backgroundColor};

          &:before {
            content: "";
            display: block;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 20px solid rgb(38, 38, 38);
            position: absolute;
            top: -31px;
            left: -10px;
          }
        `;
      default:
        return null;
    }
  }}
`;

const PresetsTab = ({
  changePreset,
}: {
  changePreset: (shape: PresetTypes) => void;
}) => {
  const { theme } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          backgroundColor: theme.sidebar.tabs.background,
          marginBottom: 2,
          textTransform: 'uppercase',
          paddingBottom: 0,
          fontWeight: 'bold',
          fontSize: '0.75em',
          color: 'rgba(188, 188, 188)',
          minHeight: 76,
        }}
      >
        <div
          style={{
            padding: '13px 13px 0 13px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>Presets</div>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setIsModalOpen(true)}
          >
            <BsFillQuestionCircleFill />
          </div>
        </div>
      </div>
      <div
        className={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          background-color: ${theme.sidebar.tabs.background};
          overflow: auto;
        `}
      >
        <div
          className={css`
            > div {
              display: flex;
              gap: 12px;
              align-items: center;
              padding: 12px 17px 12px 17px;
              font-size: 0.75em;
              cursor: pointer;

              &.active {
                // TODO: use theme
                background-color: rgb(47, 51, 62);
              }

              &:hover {
                // TODO: use theme
                background-color: rgba(47, 51, 62, 0.5);

                &.active {
                  // TODO: use theme
                  background-color: rgb(56, 61, 74);
                }
              }

              & > div:first-child {
                width: 50px;
              }
            }

            overflow-y: auto;
            padding: 5px 0 8px;
          `}
        >
          <p
            style={{
              fontSize: 13.3,
              padding: '10px 10px 5px',
            }}
          >
            ACTIONS
          </p>
          {Object.keys(ACTIONS).map((action, idx) => (
            <div
              key={idx}
              onClick={() => {
                changePreset(action as PresetTypes);
              }}
            >
              <div>
                <PresetIcon
                  backgroundColor={'#C4C4C4'}
                  shape={action as keyof typeof ACTIONS}
                />
              </div>
              <div>
                <span
                  style={{
                    fontSize: 13.3,
                  }}
                >
                  {ACTIONS[action as keyof typeof ACTIONS]}
                </span>
              </div>
            </div>
          ))}
          <p
            style={{
              fontSize: 13.3,
              padding: '10px 10px 5px',
            }}
          >
            FILL
          </p>
          {Object.keys(SHAPES).map((shape, idx) => (
            <div
              key={idx}
              onClick={() => {
                changePreset(shape as PresetTypes);
              }}
            >
              <div>
                <PresetIcon
                  backgroundColor={'#C4C4C4'}
                  shape={shape as keyof typeof SHAPES}
                />
              </div>
              <div>
                <span
                  style={{
                    fontSize: 13.3,
                  }}
                >
                  {SHAPES[shape as keyof typeof SHAPES]}
                </span>
              </div>
            </div>
          ))}
          <p
            style={{
              fontSize: 13.3,
              padding: '10px 10px 5px',
            }}
          >
            OUTLINE
          </p>
          {Object.keys(SHAPES2).map((shape, idx) => (
            <div
              key={idx}
              onClick={() => {
                changePreset(shape as PresetTypes);
              }}
            >
              <div>
                <PresetIcon
                  backgroundColor={'#C4C4C4'}
                  shape={shape as keyof typeof SHAPES2}
                />
              </div>
              <div>
                <span
                  style={{
                    fontSize: 13.3,
                  }}
                >
                  {SHAPES2[shape as keyof typeof SHAPES2]}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AcknowledgeModal
        title={'How To Use Presets'}
        handleOnClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      >
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/419jpzl1Yd4?si=z_uLR2jMBLP4anvH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </AcknowledgeModal>
    </div>
  );
};

export default PresetsTab;
