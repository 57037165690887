import React, { forwardRef, useEffect, useRef } from 'react';
import { sub as sub2, Vector2 } from '../lib/vector2';
import { WithBoundingClientRect } from '../lib/with-bounding-client-rect';
import { mouseUpEvents } from '../services/document';

export type SvgWrapperProps = Omit<
  React.SVGProps<SVGSVGElement>,
  'onMouseUp' | 'onWheel' | 'onMouseMove'
> &
  Partial<{
    onMouseUp: () => void;
    onWheel: (e: WheelEvent) => void;
    onMouseMove: (
      e: React.MouseEvent<SVGSVGElement, MouseEvent> & { x: number; y: number }
    ) => void;
    style: React.CSSProperties | undefined;
  }>;

export type SvgWrapperObject = WithBoundingClientRect;

export const SvgWrapper = forwardRef<SvgWrapperObject, SvgWrapperProps>(
  function SvgWrapper(
    { onMouseDown, onMouseMove, onMouseUp, onWheel, style, ...props },
    forwardedRef
  ) {
    const svgRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
      const listener = onMouseUp ?? (() => {});
      mouseUpEvents.addListener(listener);

      return () => {
        mouseUpEvents.removeListener(listener);
      };
    }, [onMouseUp]);

    useEffect(() => {
      const listener = (e: WheelEvent): void => {
        e.preventDefault();

        if (!svgRef.current) {
          return;
        }

        if (onWheel) {
          onWheel(e);
        }
      };
      const ref = svgRef.current;
      ref?.addEventListener('wheel', listener, { passive: false });

      return () => {
        ref?.removeEventListener('wheel', listener);
      };
    }, [onWheel]);

    return (
      <svg
        style={style}
        ref={ref => {
          if (typeof forwardedRef === 'function') {
            forwardedRef(ref);
          } else if (forwardedRef) {
            forwardedRef.current = ref;
          }
          if (ref === null) {
            return;
          }
          svgRef.current = ref;
        }}
        onMouseDown={onMouseDown}
        onMouseMove={e => {
          if (svgRef.current) {
            const rect = svgRef.current.getBoundingClientRect();
            const rectPos = [rect.left, rect.top] as Vector2;
            const clientXY = [e.clientX, e.clientY] as Vector2;

            const [x, y] = sub2(clientXY, rectPos);

            const evt = { ...e, x, y };

            onMouseMove?.(evt);
          }
        }}
        {...props}
      ></svg>
    );
  }
);
