import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { css } from '@emotion/css';
import { BiArrowFromTop, BiArrowToTop } from 'react-icons/bi';
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
  BsFillPlayFill,
  BsPauseFill,
  BsPlusCircle,
} from 'react-icons/bs';
import { TbTools } from 'react-icons/tb';
import styled, { css as styledCss } from 'styled-components';

import { IUser } from '~/config/interfaces';
import { uploadAsset } from '~/API/cloudinary';
import { BaseButton } from '~/assets/styles/styledBaseComponents';
import SharePerformanceModal from '~/components/Modals/SharePerformanceModal';
import { formatDuration } from '~/utils/format';
import { Editor } from '../Editor/Editor';
import { PerformanceProject, performance } from '../performance-project';
import {
  TimelineState,
  getCurrentFormationIndex,
  getTimelineByFormationIndex,
  time,
} from '../timeline-state';
import { useSet } from '../../hooks/use-set';
import { add } from '../../lib/vector2';
import { at, getKV, hasKV, length } from '../../lib/iterable-helpers';
import { ThemeContext } from '../../contexts/theme';
import { Timeline } from '../Timeline/Timeline';
import { PlaybackTimeline } from '../Timeline/PlaybackTimeline';
import { Button } from '../Button';
import { Tab, Tabbed } from '../Tabbed';
import Thumbnail from './Thumbnail/Thumbnail';
import { AudioTimer } from '../audiotimer';
import PerformersTab from './PerformersTab';
import PresetsTab from './PresetsTab';
import { IsVisible } from './AutoHidingElement';
import { CheckBoxToggleSwitch } from './CheckBoxToggleSwitch';
import { MAX_ENTITY_DIAMETER, MIN_ENTITY_DIAMETER } from '../constants';
import { ChatTab } from './ChatTab';
import { icon } from './icons/icons';
import {
  alignToCircle,
  alignToCircleOutline,
  alignToHorizontalLine,
  alignToSquare,
  alignToSquareOutline,
  alignToTriangle,
  alignToTriangleOutline,
  alignToVerticalLine,
  sortPerformersByName,
} from '~/utils/preset';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { useUserStore } from '~/store/userStore';
import { toast } from 'react-toastify';
import { AiFillRocket } from 'react-icons/ai';
import PriceModal from '~/components/Modals/PriceModal';
import { Video } from './Video';
import { useVideoLength } from './use-video-length';
import { VideoTimeline } from './VideoTimeline';
import AcknowledgeModal from '~/components/Modals/AcknowledgeModal';

const SpecialButton = styled.button`
  width: 100%;
  border: 1px solid rgba(180, 255, 135, 0.5);
  padding: 0.5em 1em;
  border-radius: 5px;
  background: none;
  color: rgb(180, 255, 135);
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  text-align: left;

  &:hover {
    background: rgba(180, 255, 135, 0.1);
    border: 1px solid rgba(180, 255, 135, 1);
  }
`;

// TODO: maybe move this somewhere else?
export const colorChoices = [
  '#FFFFFF',
  '#CECECE',
  '#000000',
  '#40B1AB',
  '#4299FF',
  '#806BFF',
  '#FFC042',
  '#EE3636',
  '#F7FC31',
];

function StartAtSelector({
  initialValue,
  onValueChange,
}: {
  initialValue?: string | number;
  onValueChange: (n: string) => void;
}) {
  const [value, setValue] = useState(initialValue);
  const [isOffset, setIsOffset] = useState(!!initialValue);

  useEffect(() => {
    setValue(initialValue);
    setIsOffset(!!initialValue);
  }, [initialValue]);

  return (
    <div style={{}}>
      <input
        style={{
          background: 'none',
          border: 'none',
          color: 'white',
          fontFamily: 'Helvetica neue, Helvetica, Arial, sans-serif, sans',
        }}
        type="checkbox"
        checked={isOffset}
        onChange={() => {
          if (isOffset) {
            onValueChange('0');
            setIsOffset(false);
          } else {
            onValueChange(
              typeof value === 'string' ? value : value?.toString() ?? ''
            );
            setIsOffset(true);
          }
        }}
      />{' '}
      <label>Start at</label>{' '}
      <input
        className={css`
          box-sizing: 'border-box';
          padding: 5px;
          border: none;
          background: black;
          color: white;
          font-family: Helvetica neue, Helvetica, Arial, sans-serif, sans;
          width: 70px;

          &:disabled {
            background: #333;
            color: #666;
          }
        `}
        disabled={!isOffset}
        onChange={e => {
          onValueChange(e.target.value);
          setValue(e.target.value);
        }}
        type="text"
        value={value ? value : ''}
      />
    </div>
  );
}

const EditableLabel = ({
  readOnly,
  value,
  onSubmit,
  onEnterPress,
}: {
  value: string;
  onSubmit: (value: string) => void;
  onEnterPress: () => void;
  readOnly: boolean;
}) => {
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <input
      className={css`
        border: 1px solid transparent;
        background: transparent;
        color: white;
        border-radius: 5px;
        padding: 2px 5px;
        width: 100%;

        ${!readOnly &&
        `
            &:hover {
              border: 1px solid #555;
              background: #222;
            }
            &:focus {
              border: 1px solid #666;
              background: black;
              box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.2);
            }
          `}
      `}
      value={newValue}
      readOnly={readOnly}
      onChange={e => setNewValue(e.target.value)}
      onBlur={() => {
        onSubmit(newValue);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onSubmit(newValue);
          onEnterPress();
        }
      }}
    />
  );
};
// We will have two modes:
//
// Current time mode and current formation mode
//
// Current time mode is when the user is seeking through the timeline.
//
// Current formation mode is when a formation was selected.
//
// Pressing down on the seeker handle will result in current time mode
//
// Letting go of the seeker handle will result in current formation mode
//
// 1. when user seeks in timeline, performers are likely not to move at all,
//   until a transition point is hit
// 2. when user seeks at transition point, move performers proportional to the
//   transition point
// 3. when user lets go of the seeker (mouse up)
//   a. seeker was in transition point:
//     1. seeker should move to the nearest end of the nearest formation
//   b. seeker was in formation point:
//     2. seeker should stay where it is (e.g. don't bother moving it to either
//       edge of the formation)

function randomString(length: number = 10): string {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

  return Array.from({ length })
    .map(() => chars[Math.floor(Math.random() * chars.length)])
    .join('');
}

const commonWealthIsh = new Set([
  'en-CA',
  'en-GB',
  'en-IN',
  'en-NZ',
  'en-ZA',
  'en-AU',
]);

function VideoSelection({
  videos,
  videoUrlChanged,
  timeChanged,
  isUnmuted,
  unmuteStatusChanged,
  shouldShowVideo,
  toggleShouldShowVideo: toggleShouldSHowVideo,
  deleteVideo,
  setPriceModalState,
  isPreview,
}: {
  videos: Video[] | null | undefined;
  videoUrlChanged: (url: string) => void;
  timeChanged: (time: string) => void;
  isUnmuted: boolean | undefined;
  unmuteStatusChanged: () => void;
  shouldShowVideo: boolean;
  toggleShouldShowVideo: () => void;
  deleteVideo: () => void;
  setPriceModalState: (newStaet: boolean) => void;
  isPreview: boolean;
}) {
  const { theme, label: themeLabel } = useContext(ThemeContext);
  const [isUploading, setIsUploading] = useState(false);
  const user = useUserStore(state => state.user);

  const UpgradeMsg = () => (
    <div>
      Upgrade your plan to access this feature!
      <BaseButton
        onClick={() => setPriceModalState(true)}
        backgroundColor="#FFE142"
        style={{
          marginTop: '.5em',
        }}
      >
        <p>Upgrade</p>
      </BaseButton>
    </div>
  );

  function isEmpty(): boolean {
    return !videos || videos.length <= 0;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          backgroundColor: theme.sidebar.tabs.background,
          marginBottom: 2,
          padding: 13,
          fontWeight: 'bold',
          fontSize: '0.75em',
          color: 'rgba(188, 188, 188)',
          minHeight: '100%',
        }}
      >
        <h2
          style={{
            textTransform: 'uppercase',
            marginBottom: 20,
            color: !user?.permission?.VIDEO_UPLOAD ? '#FFE142' : 'white',
          }}
        >
          Video{' '}
          {!user?.permission?.CUSTOM_STAGE_UPLOAD ? (
            <AiFillRocket color="#FFE142" />
          ) : null}
        </h2>

        {!isPreview ? (
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: '1',
              }}
            >
              <button
                style={{
                  cursor: isUploading ? 'default' : 'pointer',
                  background: 'none',
                  border: 'none',
                  color: 'rgb(180, 255, 135)',
                  padding: 0,
                  fontFamily: 'TeX Gyre Adventor',
                  fontSize: '1em',
                  opacity: isUploading ? '0.5' : 1,
                }}
                disabled={isUploading}
                onClick={() => {
                  if (!user?.permission?.VIDEO_UPLOAD) {
                    toast.warn(<UpgradeMsg />, {
                      autoClose: 5000,
                    });
                    return;
                  }
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = 'video/*';
                  input.onchange = e => {
                    setIsUploading(true);
                    const file = (e.target as HTMLInputElement).files?.[0];
                    if (!file) {
                      alert('Falied to load file');
                      return;
                    }
                    uploadAsset(file, 'auto').then(async response => {
                      if (!response) {
                        alert('Failed to upload file');
                        return;
                      }

                      videoUrlChanged(response.url);
                      setIsUploading(false);
                    });
                  };
                  input.click();
                }}
              >
                {isUploading ? (
                  'Uploading, please wait…'
                ) : (
                  <>
                    Upload {(videos?.length ?? 0) > 0 ? 'different' : null}{' '}
                    video…
                  </>
                )}
              </button>
            </div>
            {videos ? (
              <div>
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    cursor: 'pointer',
                  }}
                  onClick={deleteVideo}
                >
                  <img
                    style={{
                      width: 16,
                      height: 16,
                    }}
                    alt="Delete border"
                    src={icon(themeLabel).trash}
                  />
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
        {isEmpty() || isPreview ? null : (
          <>
            <StartAtSelector
              initialValue={!isEmpty() ? videos![0].value.offset : ''}
              onValueChange={e => {
                timeChanged(e);
              }}
            />
            <div
              style={{
                marginBottom: '5px',
              }}
            >
              <input
                type="checkbox"
                checked={isUnmuted}
                onChange={() => {
                  unmuteStatusChanged();
                }}
              />{' '}
              <label>Unmute Video</label>
            </div>

            <div>
              <input
                type="checkbox"
                checked={shouldShowVideo}
                onChange={toggleShouldSHowVideo}
              />{' '}
              <label>Show Video</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function DistanceInput({
  label,
  value,
  onChange,
  premium,
  SetPriceModalState,
}: {
  label: string;
  value: Measurement;
  onChange: (value: Measurement) => void;
  min?: number;
  premium?: boolean;
  SetPriceModalState: (newState: boolean) => void;
}) {
  const UpgradeMsg = () => (
    <div>
      Upgrade your plan to access this feature!
      <BaseButton
        onClick={() => SetPriceModalState(true)}
        backgroundColor="#FFE142"
        style={{
          marginTop: '.5em',
        }}
      >
        <p>Upgrade</p>
      </BaseButton>
    </div>
  );

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        label,
        select {
          display: block;
        }
      `}
    >
      <label
        style={{
          width: 50,
          fontSize: '0.7em',
        }}
      >
        {label}
      </label>
      <div
        style={{
          flex: '1',
          textAlign: 'right',
          paddingRight: 10,
        }}
      >
        <div
          onClick={e => {
            if (!premium) {
              toast.warn(<UpgradeMsg />, {
                autoClose: 5000,
              });
            }
          }}
        >
          <input
            style={{
              padding: 5,
              border: 'none',
              background: 'black',
              color: 'white',
              fontFamily: 'Helvetica neue, Helvetica, Arial, sans-serif, sans',
              width: 70,
            }}
            type="text"
            value={value.value}
            onChange={e => {
              onChange({
                type: value.type,
                value: e.target.value,
              });
            }}
            disabled={!premium}
          />
        </div>
      </div>
      <select
        style={{
          border: 'none',
          background: 'none',
          color: 'white',
        }}
        value={value.type}
        onChange={e => {
          onChange({
            type: e.target.value === 'FEET' ? 'FEET' : 'METERS',
            value: value.value,
          });
        }}
      >
        <option value="METERS">
          {commonWealthIsh.has(navigator.language) ? 'Metres' : 'Meters'}
        </option>
        <option value="FEET">Feet</option>
      </select>
    </div>
  );
}

function StageDesignEditor({
  markers,
  markersUpdated,
  SetPriceModalState,
}: {
  videoUrl: string;
  videoUrlUpdated: (url: string) => void;
  markers: DeepReadonly<Marker[]> | undefined | null;
  markersUpdated: (markers: Marker[]) => void;
  SetPriceModalState: (newState: boolean) => void;
}) {
  const border = markers?.find(m => m.type === 'V2_STAGE') as
    | V2Stage
    | undefined;

  const imagery = markers?.find(m => m.type === 'IMAGE') as
    | StageImage
    | undefined;

  const UpgradeMsg = () => (
    <div>
      Upgrade your plan to access this feature!
      <BaseButton
        onClick={() => SetPriceModalState(true)}
        backgroundColor="#FFE142"
        style={{
          marginTop: '.5em',
        }}
      >
        <p>Upgrade</p>
      </BaseButton>
    </div>
  );
  const updateBorder = (
    markers: DeepReadonly<Marker[]>,
    border: Partial<V2Stage>
  ) => {
    const index = markers.findIndex(m => m.type === 'V2_STAGE');
    if (index === -1) {
      return;
    }
    const newMarkers = [...markers];
    const newBorder = newMarkers[index] as V2Stage;
    newMarkers[index] = { ...newBorder, ...border };
    markersUpdated(newMarkers);
  };

  const deleteBorder = () => {
    if (!markers) {
      return;
    }
    markersUpdated(markers.filter(m => m.type !== 'V2_STAGE'));
  };

  const deleteImage = () => {
    if (!markers) {
      return;
    }
    markersUpdated(markers.filter(m => m.type !== 'IMAGE'));
  };

  const updateImagery = (
    markers: DeepReadonly<Marker[]>,
    imagery: Partial<StageImage>
  ) => {
    const index = markers.findIndex(m => m.type === 'IMAGE');
    if (index === -1) {
      throw new Error('Something went wrong');
    }
    const newMarkers = [...markers];
    const newImagery = newMarkers[index] as StageImage;
    newMarkers[index] = { ...newImagery, ...imagery };
    markersUpdated(newMarkers);
  };

  const { label: themeLabel } = useContext(ThemeContext);
  const user = useUserStore(state => state.user);

  return (
    <>
      <div
        style={{
          padding: '20px 20px 10px 20px',
          background: 'rgba(0, 0, 0, 0.25)',
          marginBottom: '2px',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
          onClick={e => {
            if (!user?.permission?.CUSTOM_STAGE_UPLOAD && border) {
              toast.warn(<UpgradeMsg />, {
                autoClose: 5000,
              });
            }
          }}
        >
          <h4
            style={{
              fontWeight: 'bold',
              fontSize: '0.65em',
              marginTop: '0.2em',
              marginBottom: '2em',
              marginRight: '.5em',
              color:
                border && !user?.permission?.CUSTOM_STAGE_UPLOAD
                  ? '#FFE142'
                  : 'white', // Adjust color conditionally
            }}
          >
            {!border ? 'Stage Design' : 'Stage Dimensions'}
          </h4>
          {!user?.permission?.CUSTOM_STAGE_UPLOAD && border && (
            <AiFillRocket color="#FFE142" />
          )}
        </div>
        {/* LOOK HERE */}
        {!markers || !border ? (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <SpecialButton
              onClick={() => {
                markersUpdated([
                  ...(markers || []),
                  {
                    type: 'V2_STAGE',
                    width: {
                      type: 'METERS',
                      value: `${16}`,
                    },
                    depth: {
                      type: 'METERS',
                      value: `${12}`,
                    },
                    topLabel: 'Upstage',
                    bottomLabel: 'Downstage',
                    leftLabel: 'Stage Right',
                    rightLabel: 'Stage Left',
                  },
                ]);
              }}
            >
              <BsPlusCircle
                style={{ position: 'relative', top: 1.5, marginRight: '6px' }}
              />{' '}
              Add Stage Borders
            </SpecialButton>
          </div>
        ) : (
          <>
            <DistanceInput
              premium={user?.permission?.CUSTOM_STAGE_UPLOAD === true}
              value={border!.width}
              label="Width"
              SetPriceModalState={SetPriceModalState}
              onChange={e => {
                if (user?.permission?.CUSTOM_STAGE_UPLOAD === true) {
                  updateBorder(markers, { width: e });
                } else {
                  toast.warn(<UpgradeMsg />, {
                    autoClose: 5000,
                  });
                }
              }}
            />
            <DistanceInput
              premium={user?.permission?.CUSTOM_STAGE_UPLOAD === true}
              value={border!.depth}
              label="Height"
              SetPriceModalState={SetPriceModalState}
              onChange={e => {
                if (user?.permission?.CUSTOM_STAGE_UPLOAD === true) {
                  updateBorder(markers, { depth: e });
                } else {
                  toast.warn(<UpgradeMsg />, {
                    autoClose: 5000,
                  });
                }
              }}
            />
            <div
              style={{
                display: 'flex',
                marginTop: '1.5em',
              }}
              onClick={e => {
                if (!user?.permission?.CUSTOM_STAGE_UPLOAD) {
                  toast.warn(<UpgradeMsg />, {
                    autoClose: 5000,
                  });
                }
              }}
            >
              <h4
                style={{
                  fontWeight: 'bold',
                  marginTop: '0.2em',
                  fontSize: '0.65em',
                  marginRight: '.5em', // Add spacing between the text and icon
                  color: !user?.permission?.CUSTOM_STAGE_UPLOAD
                    ? '#FFE142'
                    : 'white', // Adjust color conditionally
                }}
              >
                Stage Labels
              </h4>

              {!user?.permission?.CUSTOM_STAGE_UPLOAD && (
                <AiFillRocket color="#FFE142" />
              )}
            </div>
            <div
              className={css`
                margin-top: 20px;
                > * {
                  display: flex;
                  &:not(:last-child) {
                    margin-bottom: 0.5em;
                  }
                  align-items: center;
                  label,
                  input {
                    display: block;
                  }

                  label {
                    width: 60px;
                    margin-bottom: 5px;
                    font-size: 0.7em;
                  }

                  input {
                    margin-left: -6px;
                    flex: 1;
                    padding: 3px 5px;
                    background: #232323;
                    color: white;
                    border: 1px solid #666;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    font-size: 0.7em;
                  }
                }
              `}
            >
              <div
                onClick={e => {
                  if (!user?.permission?.CUSTOM_STAGE_UPLOAD) {
                    toast.warn(<UpgradeMsg />, {
                      autoClose: 5000,
                    });
                  }
                }}
              >
                <label>Left</label>{' '}
                <input
                  value={border.leftLabel}
                  onChange={e => {
                    updateBorder(markers, { leftLabel: e.target.value });
                  }}
                  disabled={!user?.permission?.CUSTOM_STAGE_UPLOAD}
                />
              </div>

              <div
                onClick={e => {
                  if (!user?.permission?.CUSTOM_STAGE_UPLOAD) {
                    toast.warn(<UpgradeMsg />, {
                      autoClose: 5000,
                    });
                  }
                }}
              >
                <label>Right</label>{' '}
                <input
                  value={border.rightLabel}
                  onChange={e => {
                    updateBorder(markers, { rightLabel: e.target.value });
                  }}
                  disabled={!user?.permission?.CUSTOM_STAGE_UPLOAD}
                />
              </div>

              <div
                onClick={e => {
                  if (!user?.permission?.CUSTOM_STAGE_UPLOAD) {
                    toast.warn(<UpgradeMsg />, {
                      autoClose: 5000,
                    });
                  }
                }}
              >
                <label>Top</label>{' '}
                <input
                  value={border.topLabel}
                  onChange={e => {
                    updateBorder(markers, { topLabel: e.target.value });
                  }}
                  disabled={!user?.permission?.CUSTOM_STAGE_UPLOAD}
                />
              </div>

              <div
                onClick={e => {
                  if (!user?.permission?.CUSTOM_STAGE_UPLOAD) {
                    toast.warn(<UpgradeMsg />, {
                      autoClose: 5000,
                    });
                  }
                }}
              >
                <label>Bottom</label>{' '}
                <input
                  value={border.bottomLabel}
                  onChange={e => {
                    updateBorder(markers, { bottomLabel: e.target.value });
                  }}
                  disabled={!user?.permission?.CUSTOM_STAGE_UPLOAD}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginTop: '0.7em',
              }}
            >
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  deleteBorder();
                }}
              >
                <img
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  alt="Delete border"
                  src={icon(themeLabel).trash}
                />
              </button>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          paddingTop: '16px',
          padding: '20px',
          background: 'rgba(0, 0, 0, 0.25)',
        }}
      >
        <div
          style={{ display: 'flex' }}
          onClick={e => {
            if (!user?.permission?.CUSTOM_STAGE_UPLOAD) {
              toast.warn(<UpgradeMsg />, {
                autoClose: 5000,
              });
            }
          }}
        >
          <h4
            style={{
              fontWeight: 'bold',
              marginTop: '0.2em',
              marginRight: '.5em',
              fontSize: '0.65em',
              marginBottom: '1.8em',
              color: !user?.permission?.CUSTOM_STAGE_UPLOAD
                ? '#FFE142'
                : 'white', // Adjust color conditionally
            }}
          >
            Imagery
          </h4>
          {!user?.permission?.CUSTOM_STAGE_UPLOAD && (
            <AiFillRocket color="#FFE142" />
          )}
        </div>

        {!markers || !imagery ? (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <SpecialButton
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                if (user?.permission?.CUSTOM_STAGE_UPLOAD) {
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = 'image/*';
                  input.onchange = e => {
                    const file = (e.target as HTMLInputElement).files?.[0];
                    if (!file) {
                      alert('Failed to load file');
                      return;
                    }

                    uploadAsset(file, 'image').then(async response => {
                      // const url = await getAssetUrl(file.name);
                      if (!response) {
                        alert('Failed to upload file');
                        return;
                      }
                      markersUpdated([
                        ...(markers || []),
                        {
                          type: 'IMAGE',
                          x: { type: 'METERS', value: '0' },
                          y: { type: 'METERS', value: '0' },
                          width: {
                            type: 'METERS',
                            value: `${16}`,
                          },
                          height: {
                            type: 'METERS',
                            value: `${12}`,
                          },
                          url: response.url,
                        },
                      ]);
                    });
                  };
                  input.click();
                } else {
                  toast.warn(<UpgradeMsg />, {
                    autoClose: 5000,
                  });
                }
              }}
            >
              <BsPlusCircle
                style={{ position: 'relative', top: 1.5, marginRight: '6px' }}
              />{' '}
              Add Imagery
            </SpecialButton>
          </div>
        ) : (
          <div>
            <DistanceInput
              premium={user?.permission?.CUSTOM_STAGE_UPLOAD === true}
              SetPriceModalState={SetPriceModalState}
              value={imagery!.width}
              label="Width"
              onChange={e => {
                updateImagery(markers, { width: e });
              }}
            />
            <DistanceInput
              premium={user?.permission?.CUSTOM_STAGE_UPLOAD === true}
              SetPriceModalState={SetPriceModalState}
              value={imagery!.height}
              label="Height"
              onChange={e => {
                updateImagery(markers, { height: e });
              }}
            />
            <DistanceInput
              premium={user?.permission?.CUSTOM_STAGE_UPLOAD === true}
              SetPriceModalState={SetPriceModalState}
              value={imagery!.x}
              label="Horizontal"
              onChange={e => {
                updateImagery(markers, { x: e });
              }}
            />
            <DistanceInput
              premium={user?.permission?.CUSTOM_STAGE_UPLOAD === true}
              SetPriceModalState={SetPriceModalState}
              value={imagery!.y}
              label="Vertical"
              onChange={e => {
                updateImagery(markers, { y: e });
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '0.7em',
                alignItems: 'top',
              }}
            >
              <button
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                  color: 'rgb(180, 255, 135)',
                  padding: 0,
                  fontFamily: 'TeX Gyre Adventor',
                  fontSize: '0.6em',
                }}
                onClick={e => {
                  e.preventDefault();
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = 'image/*';
                  input.onchange = e => {
                    const file = (e.target as HTMLInputElement).files?.[0];
                    if (!file) {
                      alert('Failed to load file');
                      return;
                    }

                    uploadAsset(file, 'image').then(async response => {
                      // const url = await getAssetUrl(file.name);
                      if (!response) {
                        alert('Failed to upload file');
                        return;
                      }
                      updateImagery(markers, {
                        url: response.url,
                      });
                    });
                  };
                  input.click();
                }}
              >
                Change Image…
              </button>

              <button
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  deleteImage();
                }}
              >
                <img
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  alt="Delete image"
                  src={icon(themeLabel).trash}
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export const PerformerIcon = styled.div<{
  backgroundColor: string;
  shape: Shape;
}>`
  position: relative;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;

  span {
    font-size: 9px;
    line-height: 9px;
    text-align: center;
    color: black;
    font-weight: 700;
  }

  ${props => {
    switch (props.shape) {
      case 'circle':
        return styledCss`
          border-radius: 50%;
          background-color: ${props.backgroundColor};
        `;
      case 'square':
        return styledCss`
          border-radius: 0%;
          background-color: ${props.backgroundColor};
        `;
      case 'triangle':
        return styledCss`
          height: 20px;
          width: 20px;
          border-radius: 0%;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 20px solid ${props.backgroundColor};

          span {
            position: absolute;
            top: 8px;
          }
        `;
      default:
        return null;
    }
  }}
`;

type ProjectProps = {
  user: IUser | null;
  performance: PerformanceProject;
  isPreview: boolean;
  onProjectUpdated: (project: PerformanceProject) => void;
};

export function Project({
  user,
  performance: perf,
  isPreview,
  onProjectUpdated,
}: ProjectProps) {
  const {
    formations,
    entities,
    markers,
    hideGrid,
    hidePaths,
    snapToGrid,
    showVideo,
    visibleEntityWidth,
    ...project
  } = perf;
  const performanceProject = useMemo(() => performance(perf), [perf]);
  const [shouldShowSidebar, setShouldShowSidebar] = useState(false);
  const [localVisibleEntityWidth, setLocalVisibleEntityWidth] =
    useState(visibleEntityWidth);
  const [isTimelineDragging, setIsTimelineDragging] = useState(false);
  const videoLength = useVideoLength(project.videos?.[0]?.value.url);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoTimestamp, setVideoTimestamp] = useState(
    parseFloat((project.videos?.[0]?.value.offset ?? '').toString())
  );
  const [isUnmuted, setIsUnmuted] = useState(false);

  useEffect(() => {
    if (visibleEntityWidth !== localVisibleEntityWidth) {
      setLocalVisibleEntityWidth(visibleEntityWidth);
    }
  }, [visibleEntityWidth]);

  const [history, setHistory] = useState<{
    versions: PerformanceProject[];
    index: number;
  }>({ versions: [perf], index: 0 });
  // const [isGridVisible, setIsGridVisible] = useState(true);
  // const [arePathsVisible, setArePathsVisible] = useState(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState<boolean>(false);

  //look here
  const projectUpdated = (proj: PerformanceProject) => {
    setHistory({
      versions: [...history.versions.slice(0, history.index + 1), proj],
      index: history.index + 1,
    });
    onProjectUpdated(proj);
  };

  const [timeline, setTimeline] = useState<TimelineState>({
    mode: 'CURRENT_FORMATION',
    index: 0,
    position: 0,
  });
  const currentFormationIndex = useMemo(
    () => getCurrentFormationIndex(performanceProject, timeline),
    [performanceProject, timeline]
  );
  const { theme, label: themeLabel } = useContext(ThemeContext);

  const selections = useSet<string>();

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioTimerRef = useRef(new AudioTimer());

  const undo = (): void => {
    if (history.index === 0) {
      return;
    }
    setHistory({ ...history, index: history.index - 1 });
    onProjectUpdated(history.versions[history.index - 1]);
  };

  const redo = (): void => {
    if (history.index >= history.versions.length - 1) {
      return;
    }
    setHistory({ ...history, index: history.index + 1 });
    onProjectUpdated(history.versions[history.index + 1]);
  };

  useEffect(() => {
    if (timeline.mode === 'CURRENT_FORMATION') {
      audioTimerRef.current.currentTime = time(performanceProject, timeline);
    }
  }, [timeline]);

  useEffect(() => {
    if (performanceProject.music?.type === 'custom') {
      const a = new Audio(performanceProject.music.value.url);
      a.onloadedmetadata = () => {
        audioTimerRef.current.setAudio(a);
      };

      return () => {
        audioTimerRef.current.setAudio(null);
      };
    }
  }, [performanceProject]);

  const isPlayingRef = useRef(isPlaying);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);  

  useEffect(() => {
    if (isPlaying) {
      isPlayingRef.current = true;
      audioTimerRef.current.start();
      const loop = () => {
        if (isPlayingRef.current) {
          let endTime = performanceProject.getEndTimeAtFormationIndex(
            performanceProject.formations.length - 1
          );
          if (endTime === undefined) return;
          if (
            !performanceProject.music &&
            audioTimerRef.current.currentTime > endTime
          ) {
            //no music mode
            setIsPlaying(false);
            setTimeline({
              mode: 'CURRENT_FORMATION',
              index: performanceProject.formations.length - 1,
              position: 1,
            });
            return; // breaks out of loop
          } else if (
            audioTimerRef.current.audioDuration &&
            audioTimerRef.current.currentTime >
              audioTimerRef.current.audioDuration
          ) {
            //music mode
            setIsPlaying(false);
            setTimeline({
              mode: 'CURRENT_FORMATION',
              index: performanceProject.formations.length - 1,
              position: 1,
            });
            return; // breaks out of loop
          } else {
            setTimeline({
              mode: 'SEEKER',
              time: audioTimerRef.current.currentTime,
            });
          }
        } else {
          const form = performanceProject.getFormationAtTime(
            audioTimerRef.current.currentTime
          );
          // TODO: handle edge case here.
          if (!form) return;
          let startTime = performanceProject.getStartTimeAtFormationIndex(
            form[0]
          );
          let endTime = performanceProject.getEndTimeAtFormationIndex(form[0]);
          if (startTime === undefined || endTime === undefined) return;
          let progress =
            (audioTimerRef.current.currentTime - startTime) /
            (endTime - startTime);
          if (progress > 1) {
            let halfMark = form[1].transitionDuration / 2;
            let transitionProgress =
              audioTimerRef.current.currentTime - endTime;
            if (transitionProgress > halfMark) {
              setTimeline({
                mode: 'CURRENT_FORMATION',
                index: form[0] + 1,
                position: 0,
              });
            } else {
              setTimeline({
                mode: 'CURRENT_FORMATION',
                index: form[0],
                position: 1,
              });
            }
          } else {
            setTimeline({
              mode: 'CURRENT_FORMATION',
              index: form[0],
              position: progress,
            });
          }
          return; //breaks out of loop
        }
        return requestAnimationFrame(loop);
      };
      const animationFrame = requestAnimationFrame(loop);

      return () => {
        cancelAnimationFrame(animationFrame);
      };
    } else {
      isPlayingRef.current = false;
      audioTimerRef.current.pause();
      const form = performanceProject.getFormationAtTime(
        audioTimerRef.current.currentTime
      );
      // TODO: handle edge case here.
      if (!form) return;

      setTimeline({
        mode: 'CURRENT_FORMATION',
        index: form[0],
        position: 1,
      });
    }
  }, [isPlaying]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      )
        return;
      if ((e.ctrlKey || e.metaKey) && (e.key === 'z' || e.key === 'y')) {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === 'z') {
          undo();
        } else {
          redo();
        }
      } else if (e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        setIsPlaying(!isPlaying);
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [performanceProject, history, isPlaying]);

  // TODO: unit test this
  const newFormationName = (): string => {
    const latest =
      [...formations]
        .map(({ name }) => name)
        .filter(name => /^Formation \d+$/.test(name))
        .map(n => parseInt(n.split(' ')[1]))
        .sort((a, b) => b - a)[0] ?? 0;

    return `Formation ${latest + 1}`;
  };

  const duplicateFormationName = (originalName: string): string => {
    return `${originalName} copy`;
  };

  const newPerformerName = (): string => {
    const latest =
      [...entities]
        .map(([, { name }]) => name)
        .filter(name => /^Performer \d+$/.test(name))
        .map(n => parseInt(n.split(' ')[1]))
        .sort((a, b) => b - a)[0] ?? 0;

    return `Performer ${latest + 1}`;
  };

  const addEntity = useCallback(() => {
    let draft = { formations, entities, ...project };

    // The basis case where the formations list is empty:

    if ([...formations].length === 0) {
      draft = performance(draft).pushFormation(newFormationName(), 5000, 1000);
    }

    const allPlacements = [
      ...performance(draft).getFormationByIndex(currentFormationIndex)
        .placements,
    ];

    const lastEntityPlacement = allPlacements[allPlacements.length - 1];
    let position: [number, number] = [0, 0];
    if (lastEntityPlacement) {
      position = add(lastEntityPlacement[1].position, [10, -10]);
    }

    const placement = { position };

    let id = randomString();
    while (hasKV(entities, id)) {
      id = randomString();
    }

    const lastEntity = at(entities, length(entities) - 1);

    draft = performance(draft).addEntity(id, {
      // TODO: use a colour that belongs to the last performer
      color:
        lastEntity && lastEntity[1].color
          ? lastEntity[1].color
          : colorChoices[Math.floor(Math.random() * colorChoices.length)],
      name: newPerformerName(),
      shape: 'circle',
    });

    draft = performance(draft)
      .getFormationByIndex(0)
      .entity(id)
      .setPlacement(placement);

    projectUpdated(draft);
  }, [performanceProject]);

  const handlePreset = (shape: PresetTypes): void => {
    const allPlacements = [
      ...performanceProject.getFormationByIndex(currentFormationIndex)
        .placements,
    ];

    const selectedPlacements = allPlacements.filter(
      (placement: [string, EntityPlacement]) => selections.has(placement[0])
    );

    if (selectedPlacements.length > 0) {
      let aligned: { [key: string]: [number, number] } = {};
      switch (shape) {
        case 'sortByNameDescending':
          aligned = sortPerformersByName(selectedPlacements, entities, 'desc');
          break;
        case 'sortByName':
          aligned = sortPerformersByName(selectedPlacements, entities);
          break;

        case 'circle':
          aligned = alignToCircle(selectedPlacements);
          break;

        case 'square':
          aligned = alignToSquare(selectedPlacements);
          break;

        case 'triangle':
          aligned = alignToTriangle(selectedPlacements);
          break;

        case 'upsideDownTriangle':
          aligned = alignToTriangle(selectedPlacements, true);
          break;

        case 'horizontalLine':
          aligned = alignToHorizontalLine(selectedPlacements);
          break;

        case 'verticalLine':
          aligned = alignToVerticalLine(selectedPlacements);
          break;

        case 'circleOutline':
          aligned = alignToCircleOutline(selectedPlacements);
          break;

        case 'squareOutline':
          aligned = alignToSquareOutline(selectedPlacements);
          break;

        case 'triangleOutline':
          aligned = alignToTriangleOutline(selectedPlacements);
          break;

        case 'upsideDownTriangleOutline':
          aligned = alignToTriangleOutline(selectedPlacements, true);
          break;

        default:
          break;
      }

      if (Object.keys(aligned).length > 0) {
        const changes = allPlacements.map(([index, { position }]) => {
          if (aligned[index]) {
            return [index, aligned[index]];
          }
          return [index, position];
        });
        const draft = performanceProject
          .getFormationByIndex(currentFormationIndex)
          .setPositions(changes as Iterable<[string, Vector2]>);
        projectUpdated(draft);
      }
    }
  };

  const goToFirstFormation = (): void => {
    setIsPlaying(false);
    setTimeline({
      mode: 'CURRENT_FORMATION',
      index: 0,
      position: 0,
    });
  };

  const goToLastFormation = (): void => {
    setIsPlaying(false);
    setTimeline({
      mode: 'CURRENT_FORMATION',
      index: performanceProject.formations.length - 1,
      position: 0,
    });
  };

  const goToPrevFormation = (): void => {
    const index =
      currentFormationIndex > 0
        ? currentFormationIndex - 1
        : currentFormationIndex;
    setIsPlaying(false);
    setTimeline({
      mode: 'CURRENT_FORMATION',
      index,
      position: 0,
    });
  };

  const goToNextFormation = (): void => {
    const index =
      currentFormationIndex < performanceProject.formations.length - 1
        ? currentFormationIndex + 1
        : currentFormationIndex;
    setIsPlaying(false);
    setTimeline({
      mode: 'CURRENT_FORMATION',
      index,
      position: 0,
    });
  };

  return (
    <>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          background: theme.background,
          color: themeLabel === 'dark' ? 'white' : 'black',
        }}
      >
        <div
          style={{
            height: 56,
            display: 'flex',
          }}
        >
          <div
            style={{
              flex: '1',
            }}
          >
            <a href="/">
              <img
                src={icon(themeLabel).stageKeep}
                style={{ height: 25, marginLeft: 20, marginTop: 15.5 }}
              />
            </a>
          </div>
          <div
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginRight: 15,
              }}
            >
              <img
                style={{
                  height: 36,
                  width: 36,
                  objectFit: 'cover',
                  borderRadius: 4,
                }}
                src={project.imageUrl || '/images/SKImageGreen.png'}
              />
            </div>
            <h1
              style={{
                fontWeight: 'bold',
                fontSize: '0.9em',
              }}
            >
              {project.name}
            </h1>
          </div>
          <div
            style={{
              flex: '1',
              justifyContent: 'flex-end',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            {user?.permission?.PUBLIC_SHARING && (
              <BaseButton
                onClick={() => setIsShareModalOpen(true)}
                backgroundColor="#4299FF"
                style={{
                  marginRight: 15,
                }}
              >
                <p>Share</p>
              </BaseButton>
            )}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  marginTop: 5,
                  paddingRight: 20,
                }}
              >
                <button
                  style={{
                    background: 'none',
                    padding: '0',
                    border: 'none',
                    margin: 0,
                    fontSize: '1em',
                  }}
                  onClick={() => {
                    setShouldShowSidebar(!shouldShowSidebar);
                  }}
                >
                  <TbTools
                    style={{ color: shouldShowSidebar ? '#4287f5' : 'white' }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: '1',
            display: 'flex',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              width: 280,
              background: theme.sidebar.background,
              overflow: 'auto',
              height: '100%',
            }}
          >
            <Tabbed style={{ overflow: 'hidden', height: '100%' }}>
              <Tab icon={icon(themeLabel).formations}>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: theme.sidebar.tabs.background,
                      marginBottom: 2,
                      textTransform: 'uppercase',
                      paddingBottom: 0,
                      fontWeight: 'bold',
                      fontSize: '0.75em',
                      color: 'rgba(188, 188, 188)',
                      minHeight: 76,
                    }}
                  >
                    <div
                      style={{
                        padding: '13px 13px 0 13px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                    <div>Formations</div>
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      <BsFillQuestionCircleFill />
                    </div>
                    </div>
                  <div
                    style={{
                      backgroundColor: theme.sidebar.tabs.background,
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      marginTop: '10px',
                      display: 'flex',
                      fontSize:'0.75em',
                    }}
                  >
                    {!isPreview && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginLeft: '3px',
                        }}
                      >
                        <button
                          style={{
                            display: 'block',
                            border: 'none',
                            background: 'none',
                            padding: '10px 10px 10px 10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            const name = duplicateFormationName(
                              formations[currentFormationIndex].name
                            );

                            const cloned = performanceProject.cloneFormation(
                              currentFormationIndex
                            );

                            cloned.name = name;

                            const ids = new Set(formations.map(({ id }) => id));
                            let idNumber = formations.length;
                            while (ids.has(idNumber.toString())) {
                              idNumber++;
                            }

                            cloned.id = idNumber.toString();

                            projectUpdated(
                              performanceProject.insertFormation(
                                cloned,
                                currentFormationIndex
                              )
                            );

                            setTimeline({
                              mode: 'CURRENT_FORMATION',
                              index: currentFormationIndex + 1,
                              position: 0,
                            });
                          }}
                        >
                          <img
                            src={icon(themeLabel).duplicate}
                            alt="Duplicate formation"
                          />
                        </button>
                        {formations.length > 1 && !isPreview ? (
                          <button
                            style={{
                              display: 'block',
                              border: 'none',
                              background: 'none',
                              padding: 0,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              const newPerformance =
                                performanceProject.deleteFormation(
                                  currentFormationIndex
                                );
                              projectUpdated(newPerformance);
                              if (
                                currentFormationIndex >=
                                newPerformance.formations.length
                              ) {
                                // setCurrentFormationIndex(formations.length - 1);
                                setTimeline({
                                  mode: 'CURRENT_FORMATION',
                                  index: newPerformance.formations.length - 1,
                                  position: 0,
                                });
                              }
                            }}
                          >
                            <img
                              src={icon(themeLabel).trash}
                              alt="Delete formation"
                            />
                          </button>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}
                  </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: theme.sidebar.tabs.background,
                      overflow: 'hidden',
                      width: '100%',
                      height: '100%',
                      flex: 1,
                      flexDirection: 'column',
                      display: 'flex',
                    }}
                  >
                    <div
                      className={css`
                        padding: 13px;
                        overflow-y: scroll;
                        width: 100%;
                        flex: 1;

                        > div {
                          box-sizing: content-box;
                          height: 60px;
                          border: 2px solid
                            ${theme.formationsList.listItem.unselected};
                          background-color: ${theme.formationsList.listItem
                            .unselected};
                          border-radius: 5px;

                          align-items: center;

                          display: flex;

                          padding: 7px 7px 7px 4px;
                          cursor: pointer;

                          &:not(:last-child) {
                            margin-bottom: 10px;
                          }

                          > div:first-child {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100%;
                            align-items: center;
                            margin-right: 3px;
                            min-width: 14px;
                          }

                          > div:nth-child(2) {
                            background: rgb(27, 27, 27);
                            width: 60px;
                            height: 60px;
                            margin-right: 12px;
                          }

                          > h4:last-child {
                            font-size: 0.7em;
                            flex: 1;
                            margin: 0;
                            padding: 0;
                          }
                        }
                      `}
                    >
                      {[...formations].map((formation, index) => (
                        <IsVisible
                          onClick={() => {
                            setTimeline(
                              getTimelineByFormationIndex(
                                performanceProject,
                                index
                              )
                            );
                          }}
                          key={formation.id}
                          style={{
                            backgroundColor:
                              index === currentFormationIndex
                                ? theme.formationsList.listItem.selected
                                    .background
                                : undefined,
                            borderColor:
                              index === currentFormationIndex
                                ? theme.formationsList.listItem.selected.border
                                : undefined,
                          }}
                        >
                          {isVisible => (
                            <>
                              <div>
                                {!isPreview && (
                                  <>
                                    <div>
                                      {index !== 0 && (
                                        <BiArrowToTop
                                          color="white"
                                          size={14}
                                          onClick={() => {
                                            projectUpdated(
                                              performanceProject.swapFormations(
                                                index - 1,
                                                index
                                              )
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div>
                                      {index < formations.length - 1 && (
                                        <BiArrowFromTop
                                          color="white"
                                          size={14}
                                          onClick={() => {
                                            projectUpdated(
                                              performanceProject.swapFormations(
                                                index,
                                                index + 1
                                              )
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>

                              <div>
                                {isVisible ? (
                                  <Thumbnail
                                    entities={entities}
                                    placements={
                                      performanceProject.getFormationByIndex(
                                        index
                                      ).placements
                                    }
                                    selectedEntities={selections.readOnlySet}
                                  />
                                ) : null}
                              </div>
                              <div>
                                {isPreview ? (
                                  <h5
                                    style={{
                                      fontSize: 14,
                                    }}
                                  >
                                    {formation.name}
                                  </h5>
                                ) : (
                                  <EditableLabel
                                    value={formation.name}
                                    readOnly={Boolean(
                                      isPreview ||
                                        !user?.permission?.FORMATION_LABELS
                                    )}
                                    onEnterPress={() => {
                                      (
                                        document.activeElement as HTMLInputElement
                                      ).blur?.();
                                    }}
                                    onSubmit={value => {
                                      // updateFormat(id, value);
                                      projectUpdated(
                                        performanceProject.updateFormationName(
                                          index,
                                          value
                                        )
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </IsVisible>
                      ))}
                    </div>
                    {!isPreview && (
                      <div
                        style={{
                          textAlign: 'center',
                          paddingTop: '10px',
                          paddingBottom: '20px',
                        }}
                      >
                        <Button
                          onClick={() => {
                            projectUpdated(
                              performanceProject.pushFormation(
                                newFormationName(),
                                5000,
                                3000
                              )
                            );
                          }}
                        >
                          New Formation
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Tab>

              <Tab icon={icon(themeLabel).film}>
                <VideoSelection
                  isPreview={isPreview}
                  setPriceModalState={setIsPriceModalOpen}
                  isUnmuted={perf.videos?.[0]?.value.unmuted ?? false}
                  videos={perf.videos}
                  unmuteStatusChanged={() => {
                    const videos = performanceProject.videos;
                    if (videos === null || videos === undefined) {
                      return;
                    }
                    const v = videos.find(video => video.type === 'DIRECT_URL');
                    if (!v) {
                      return;
                    }
                    projectUpdated(
                      performanceProject.setVideos([
                        {
                          type: 'DIRECT_URL',
                          value: {
                            ...v.value,
                            unmuted: !v.value.unmuted,
                          },
                        },
                      ])
                    );
                  }}
                  timeChanged={offset => {
                    const videos = performanceProject.videos;
                    if (videos === null || videos === undefined) {
                      return;
                    }
                    const v = videos.find(video => video.type === 'DIRECT_URL');
                    if (!v) {
                      return;
                    }
                    projectUpdated(
                      performanceProject.setVideos([
                        {
                          type: 'DIRECT_URL',
                          value: { ...v.value, offset },
                        },
                      ])
                    );
                  }}
                  videoUrlChanged={url => {
                    projectUpdated(
                      performanceProject.setVideos(
                        !url ? null : [{ type: 'DIRECT_URL', value: { url } }]
                      )
                    );
                  }}
                  shouldShowVideo={!!showVideo}
                  toggleShouldShowVideo={() => {
                    projectUpdated(performanceProject.setShowVideo(!showVideo));
                  }}
                  deleteVideo={() => {
                    projectUpdated(performanceProject.setVideos(null));
                  }}
                />
              </Tab>

              <Tab icon={icon(themeLabel).performers}>
                <PerformersTab
                  isPreview={isPreview}
                  onDeleteSelectedEntities={() => {
                    projectUpdated(
                      performanceProject.deleteEntities(selections)
                    );
                  }}
                  onAddEntity={addEntity}
                  entities={entities}
                  selections={selections.readOnlySet}
                  selectionsChanged={s => {
                    selections.clear();
                    for (const selection of s) {
                      selections.add(selection);
                    }
                  }}
                  changeShape={shape => {
                    projectUpdated(
                      performanceProject.setEntities(
                        [...entities]
                          .filter(([id]) => selections.has(id))
                          .map(([id, entity]) => [id, { ...entity, shape }])
                      )
                    );
                  }}
                  changeColor={color => {
                    projectUpdated(
                      performanceProject.setEntities(
                        [...entities]
                          .filter(([id]) => selections.has(id))
                          .map(([id, entity]) => [id, { ...entity, color }])
                      )
                    );
                  }}
                  changeName={(id, name) => {
                    const entity = getKV(entities, id);
                    if (entity) {
                      if (entity.name !== name) {
                        projectUpdated(
                          performanceProject.setEntity(id, { ...entity, name })
                        );
                      }
                    } else {
                      console.error(
                        'Entity not found, for some insanely weird reason 😲',
                        id
                      );
                    }
                  }}
                />
              </Tab>
              {!isPreview ? (
                <Tab icon={icon(themeLabel).presets}>
                  <PresetsTab
                    changePreset={shape => {
                      handlePreset(shape);
                    }}
                  />
                </Tab>
              ) : (
                <></>
              )}
              <Tab
                icon={selected => (
                  <svg
                    opacity={selected ? 1 : 0.5}
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32zM128 272c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path>
                  </svg>
                )}
              >
                {/*
                  TODO: how the fuck can we allow the fact that we are passing
                  in incomplete project infromation????
                 */}
                <ChatTab
                  timelineState={timeline}
                  performance={performanceProject}
                  timelineSeeked={t => {
                    if (t > performanceProject.totalTime)
                      t = performanceProject.totalTime;
                    const form = performanceProject.getFormationAtTime(t);
                    if (!form) return;
                    setTimeline({
                      mode: 'SEEKER',
                      time: t,
                    });
                    audioTimerRef.current.currentTime = time(
                      performanceProject,
                      timeline
                    );
                  }}
                  isPreview={isPreview}
                />
              </Tab>
            </Tabbed>
          </div>

          <div
            style={{
              display: 'flex',
              flex: '1',
              height: '100%',
              width: '100vw',
              backgroundColor: '#0f0f0f',
            }}
          >
            {showVideo &&
            !!project.videos &&
            project.videos.length > 0 &&
            !!project.videos[0] &&
            project.videos![0].type === 'DIRECT_URL' &&
            project.videos![0].value &&
            project.videos![0].value.url.trim() ? (
              <div
                style={{
                  flex: '1',
                  padding: 30,
                  boxSizing: 'border-box',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Video
                  startTime={
                    isTimelineDragging || isPlaying
                      ? parseFloat(
                          (project.videos[0].value.offset || '').toString()
                        ) +
                        time(performanceProject, timeline) / 1000
                      : videoTimestamp
                  }
                  startTimeChanged={e => {
                    setVideoTimestamp(e);
                  }}
                  isPlaying={
                    isTimelineDragging ? false : isPlaying || isVideoPlaying
                  }
                  style={{
                    width: '100%',
                  }}
                  src={project.videos[0].value.url.trim()}
                  muted={!project.videos[0].value.unmuted}
                />
                {isPreview ? null : (
                  <>
                    <VideoTimeline
                      offset={parseFloat(
                        (project.videos?.[0].value.offset ?? '').toString()
                      )}
                      totalLength={videoLength ?? 0}
                      timestamp={videoTimestamp}
                      onDrag={amount => {
                        setVideoTimestamp(amount);
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 15,
                        fontSize: '0.7em',
                        fontFamily:
                          "'Helvetica neue', Helvetica, Arial, sans-serif, sans",
                      }}
                    >
                      <div>
                        {formatDuration(videoTimestamp * 1000, false)} /{' '}
                        {formatDuration((videoLength ?? 0) * 1000, false)}
                      </div>
                      <div>
                        {isVideoPlaying ? (
                          <BsPauseFill
                            onClick={() => setIsVideoPlaying(false)}
                          />
                        ) : (
                          <BsFillPlayFill
                            onClick={() => setIsVideoPlaying(true)}
                          />
                        )}
                      </div>
                      <div>
                        <button
                          style={{
                            background: theme.primary,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            border: 'none',
                            padding: '0.5em 1em',
                            borderRadius: '7px',
                            fontSize: '0.7em',
                            marginTop: -10,
                          }}
                          onClick={() => {
                            projectUpdated(
                              performanceProject.setVideos([
                                {
                                  type: 'DIRECT_URL',
                                  value: {
                                    ...project.videos![0].value,
                                    offset: videoTimestamp,
                                  },
                                },
                              ])
                            );
                          }}
                        >
                          Set As Start Time
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            <Editor
              showGrid={!hideGrid}
              showPaths={hidePaths}
              snapGrid={!snapToGrid}
              isPreview={isPreview}
              timelineState={timeline}
              performance={performanceProject}
              style={{
                flex: '1',
                height: '100%',
              }}
              selections={selections}
              onPositionsChange={(changes, formationIndex) => {
                projectUpdated(
                  performanceProject
                    .getFormationByIndex(formationIndex)
                    .setPositions(changes)
                );
              }}
              onSelectionsChange={newSelections => {
                selections.clear();
                selections.add(...newSelections);
              }}
              onFormationIndexChange={i => {
                setTimeline(getTimelineByFormationIndex(performanceProject, i));
              }}
              entityDiameter={localVisibleEntityWidth || MIN_ENTITY_DIAMETER}
            />
          </div>

          {!shouldShowSidebar ? null : (
            <div
              style={{
                width: 290,
                background: 'rgb(38, 38, 38)',
                overflow: 'scroll',
              }}
            >
              <div
                style={{
                  padding: 20,
                }}
              >
                <h3
                  style={{
                    fontWeight: 'bold',
                    fontSize: '0.75em',
                    marginBottom: '1em',
                  }}
                >
                  View
                </h3>

                <div
                  className={css`
                    & > * {
                      &:not(:last-child) {
                        margin-bottom: 1em;
                      }
                      display: flex;
                      align-items: center;
                      &>div: first-child {
                        font-size: 0.7em;
                        flex: 1;
                      }
                    }
                  `}
                >
                  <div>
                    <div>Grid</div>{' '}
                    <CheckBoxToggleSwitch
                      checked={!hideGrid}
                      onChange={value => {
                        projectUpdated(performanceProject.setHideGrid(!value));
                      }}
                    />
                  </div>

                  {!hideGrid && (
                    <div>
                      <div>Snap to Grid</div>{' '}
                      <CheckBoxToggleSwitch
                        checked={!snapToGrid}
                        onChange={value => {
                          projectUpdated(
                            performanceProject.setSnapToGrid(!value)
                          );
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <div>Paths</div>{' '}
                    <CheckBoxToggleSwitch
                      checked={hidePaths}
                      onChange={value => {
                        projectUpdated(performanceProject.setHidePaths(value));
                      }}
                    />
                  </div>

                  <div
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <div style={{}}>Performer Size</div>

                    <div
                      style={{
                        marginTop: '-4px',
                        textAlign: 'right',
                      }}
                    >
                      <input
                        type="range"
                        min={MIN_ENTITY_DIAMETER}
                        max={MAX_ENTITY_DIAMETER}
                        value={localVisibleEntityWidth || MIN_ENTITY_DIAMETER}
                        onChange={e => {
                          const result = Math.min(
                            Math.max(
                              Number(e.target.value),
                              MIN_ENTITY_DIAMETER
                            ),
                            MAX_ENTITY_DIAMETER
                          );
                          setLocalVisibleEntityWidth(result);
                        }}
                        onMouseUp={() => {
                          if (
                            localVisibleEntityWidth !== visibleEntityWidth &&
                            localVisibleEntityWidth
                          ) {
                            projectUpdated(
                              performanceProject.setVisibleEntityWidth(
                                localVisibleEntityWidth
                              )
                            );
                          }
                        }}
                        step={0.01}
                        className={css`
                          -webkit-appearance: none;
                          appearance: none;
                          background: transparent;
                          cursor: pointer;
                          width: 75%;

                          /* Removes default focus */
                          &:focus {
                            outline: none;
                          }

                          /***** Chrome, Safari, Opera and Edge Chromium styles *****/
                          /* slider track */
                          &::-webkit-slider-runnable-track {
                            background-color: #53634b;
                            border-radius: 0.5rem;
                            height: 0.5rem;
                          }

                          /* slider thumb */
                          &::-webkit-slider-thumb {
                            -webkit-appearance: none; /* Override default look */
                            appearance: none;
                            margin-top: -0.25rem; /* Centers thumb on the track */

                            /*custom styles*/
                            background-color: rgb(180, 255, 146);
                            height: 1rem;
                            width: 1rem;
                            border-radius: 10px;
                          }

                          &:focus::-webkit-slider-thumb {
                            border: 1px solid #53634b;
                            outline: 3px solid #53634b;
                            outline-offset: 0.125rem;
                          }

                          /******** Firefox styles ********/
                          /* slider track */
                          &::-moz-range-track {
                            background-color: #53634b;
                            border-radius: 0.5rem;
                            height: 0.5rem;
                          }

                          /* slider thumb */
                          &::-moz-range-thumb {
                            border: none; /*Removes extra border that FF applies*/
                            border-radius: 0; /*Removes default border-radius that FF applies*/

                            /*custom styles*/
                            background-color: #5cd5eb;
                            height: 2rem;
                            width: 1rem;
                          }

                          &:focus::-moz-range-thumb {
                            border: 1px solid #53634b;
                            outline: 3px solid #53634b;
                            outline-offset: 0.125rem;
                          }
                        `}
                      />
                    </div>
                  </div>

                  <div>
                    <div>Show Video</div>{' '}
                    <CheckBoxToggleSwitch
                      checked={!!showVideo}
                      onChange={value => {
                        projectUpdated(performanceProject.setShowVideo(value));
                      }}
                    />
                  </div>
                </div>
              </div>

              <StageDesignEditor
                videoUrlUpdated={url => {
                  projectUpdated(
                    performanceProject.setVideos([
                      {
                        type: 'DIRECT_URL',
                        value: {
                          url: url.trim(),
                        },
                      },
                    ])
                  );
                }}
                markers={performanceProject.markers}
                markersUpdated={markers => {
                  projectUpdated(performanceProject.setMarkers(markers));
                }}
                SetPriceModalState={setIsPriceModalOpen}
              />
            </div>
          )}
        </div>

        {isPreview ? (
          <PlaybackTimeline
            performance={performanceProject}
            timelineState={timeline}
            timelineSeeked={t => {
              if (t > performanceProject.totalTime)
                t = performanceProject.totalTime;
              const form = performanceProject.getFormationAtTime(t);
              if (!form) return;
              setTimeline({
                mode: 'SEEKER',
                time: t,
              });
              audioTimerRef.current.currentTime = time(
                performanceProject,
                timeline
              );
            }}
          />
        ) : (
          <Timeline
            formationTimesChanged={formationTimes => {
              projectUpdated(
                performanceProject.updateFormationTimes(formationTimes)
              );
            }}
            formationSelected={i => {
              setTimeline(getTimelineByFormationIndex(performanceProject, i));
            }}
            performance={performanceProject}
            timelineState={timeline}
            currentFormationIndex={currentFormationIndex}
            timelineStoppedSeeking={time => {
              setIsTimelineDragging(false);
              const form = performanceProject.getFormationAtTime(time);
              if (!form) return;
              let startTime = performanceProject.getStartTimeAtFormationIndex(
                form[0]
              );
              let endTime = performanceProject.getEndTimeAtFormationIndex(
                form[0]
              );
              if (startTime === undefined || endTime === undefined) return;
              let progress =
                (audioTimerRef.current.currentTime - startTime) /
                (endTime - startTime);
              if (progress > 1) {
                let halfMark = form[1].transitionDuration / 2;
                let transitionProgress =
                  audioTimerRef.current.currentTime - endTime;
                if (transitionProgress > halfMark) {
                  setTimeline({
                    mode: 'CURRENT_FORMATION',
                    index: form[0] + 1,
                    position: 0,
                  });
                } else {
                  setTimeline({
                    mode: 'CURRENT_FORMATION',
                    index: form[0],
                    position: 1,
                  });
                }
              } else {
                setTimeline({
                  mode: 'CURRENT_FORMATION',
                  index: form[0],
                  position: progress,
                });
              }
            }}
            timelineSeeked={t => {
              setIsTimelineDragging(true);
              if (t > performanceProject.totalTime)
                t = performanceProject.totalTime;
              const form = performanceProject.getFormationAtTime(t);
              if (!form) return;
              setTimeline({
                mode: 'SEEKER',
                time: t,
              });
              audioTimerRef.current.currentTime = time(
                performanceProject,
                timeline
              );
            }}
          />
        )}
        <div
          style={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              flex: '1',
              fontSize: '0.75em',
              marginTop: 2,
              fontFamily:
                '"Helvetica Neue", Helvetica, Arial, sans-serif, sans',
            }}
          >
            {formatDuration(time(performanceProject, timeline))} -{' '}
            {formatDuration(performanceProject.totalTime)}
          </div>
          <div style={{ flex: '1' }}></div>
          <div style={{ display: 'inline-flex', gap: 20 }}>
            <BsChevronDoubleLeft onClick={() => goToFirstFormation()} />
            <BsChevronLeft onClick={() => goToPrevFormation()} />
            <div>
              {isPlaying ? (
                <BsPauseFill onClick={() => setIsPlaying(false)} />
              ) : (
                <BsFillPlayFill onClick={() => setIsPlaying(true)} />
              )}
            </div>
            <BsChevronRight onClick={() => goToNextFormation()} />
            <BsChevronDoubleRight onClick={() => goToLastFormation()} />
          </div>
          <div style={{ flex: '1' }}></div>
          <div
            className={css`
              flex: 1;
              text-align: right;

              button {
                background: none;
                border: none;

                &:not([disabled]) {
                  cursor: pointer;
                }

                &[disabled] {
                  opacity: 0.5;
                }
              }
            `}
          >
            {!isPreview && (
              <>
                <button disabled={history.index <= 0} onClick={() => undo()}>
                  <img src={icon(themeLabel).undo} alt="Undo" />
                </button>
                <button
                  disabled={history.index >= history.versions.length - 1}
                  onClick={() => redo()}
                >
                  <img src={icon(themeLabel).redo} alt="Redo" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <SharePerformanceModal
        handleOnClose={() => setIsShareModalOpen(false)}
        isOpen={isShareModalOpen}
      />
      <PriceModal
        isPriced={Boolean(user?.isPriced)}
        isOpen={isPriceModalOpen}
        handleOnClose={() => setIsPriceModalOpen(false)}
      />
      <AcknowledgeModal
        title={'How To Create Formations'}
        handleOnClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      >
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/bCyjomXmfPs?si=466_W8MwlCvv6dMN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </AcknowledgeModal>
    </>
  );
}
