import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { css, cx } from '@emotion/css';

import { ThemeContext } from '../../contexts/theme';
import { ReadOnlySet } from '../../lib/readonly-map-set';
import { PerformerIcon, colorChoices } from './Project';
import { icon } from './icons/icons';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { fuzzySearch } from '~/utils/fuzzySearch';
import AcknowledgeModal from '~/components/Modals/AcknowledgeModal';

const buttonStyleClass = css`
  cursor: pointer;
  background: none;
  border: none;
  padding: 5px;
`;

const EditableLabel = ({
  value,
  onSubmit,
  onEnterPress,
}: {
  value: string;
  onSubmit: (value: string) => void;
  onEnterPress: () => void;
}) => {
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <input
      className={css`
        border: 1px solid transparent;
        background: transparent;
        color: white;
        border-radius: 5px;
        padding: 2px 10px;

        &:hover {
          border: 1px solid #555;
          background: #222;
        }
        &:focus {
          border: 1px solid #666;
          background: black;
          box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.2);
        }
      `}
      value={newValue}
      onChange={e => setNewValue(e.target.value)}
      onBlur={() => {
        onSubmit(newValue);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onSubmit(newValue);
          onEnterPress();
        }
      }}
    />
  );
};

function useOutsideClick<T extends HTMLElement>(
  callback: (e: MouseEvent) => void
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback(e);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
}

const ButtonWithDropDown = ({
  children,
  style,
  dropDownChildren,
  className,
}: {
  children?: ReactNode;
  dropDownChildren: ReactNode | ((hide: () => void) => ReactNode);
  style?: React.CSSProperties;
  className?: string;
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const ref = useOutsideClick<HTMLDivElement>(() => {
    setShowDropDown(false);
  });

  const dropDownChild =
    typeof dropDownChildren === 'function'
      ? dropDownChildren(() => setShowDropDown(false))
      : dropDownChildren;

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
      }}
    >
      <button
        className={cx({
          'button-with-dropdown': showDropDown,
          ...(className ? { [className]: true } : {}),
        })}
        ref={buttonRef}
        style={style}
        onClick={() => setShowDropDown(!showDropDown)}
      >
        {children}
      </button>

      {showDropDown ? (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 1000,
          }}
        >
          {dropDownChild}
        </div>
      ) : null}
    </div>
  );
};

const PerformersTab = ({
  isMobile,
  isPreview,
  onAddEntity,
  entities,
  selections,
  selectionsChanged,
  changeColor,
  changeShape,
  changeName,
  onDeleteSelectedEntities,
}: {
  isMobile: boolean;
  isPreview: boolean;
  onAddEntity: () => void;
  entities: UnorderedIterable<[string, Entity]>;
  selections: ReadOnlySet<string>;
  selectionsChanged: (selections: ReadOnlySet<string>) => void;
  changeColor: (color: string) => void;
  changeShape: (shape: Shape) => void;
  onDeleteSelectedEntities: () => void;
  changeName: (id: string, name: string) => void;
}) => {
  const { theme, label: themeLabel } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [performersQuery, setPerformersQuery] = useState('');
  const performersListRef = useRef<HTMLDivElement>(null);
  const shiftKeyStartRef = useRef<string | null>(null);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!isMobile && (
        <div
          style={{
            backgroundColor: theme.sidebar.tabs.background,
            marginBottom: 2,
            textTransform: 'uppercase',
            paddingBottom: 0,
            fontWeight: 'bold',
            fontSize: '0.75em',
            color: 'rgba(188, 188, 188)',
            minHeight: 76,
          }}
        >
          <div
            style={{
              padding: '13px 13px 0 13px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>Performers</div>{' '}
            <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setIsModalOpen(true)}
          >
            <BsFillQuestionCircleFill />
            </div>
            <div
              style={{
                marginTop: '-2px',
              }}
            >
              <input
                style={{
                  display: 'inline-block',
                  backgroundColor: 'rgb(58, 58, 58)',
                  width: '130px',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '4px 8px',
                  lineHeight: '10px',
                  fontSize: '0.7em',
                  color: 'rgb(196, 196, 196)',
                  marginTop: '-5px',
                }}
                value={performersQuery}
                onChange={e => setPerformersQuery(e.target.value)}
                placeholder="Search for performers"
              />
            </div>
          </div>

          <div
            style={{
              backgroundColor: theme.sidebar.tabs.background,
              textTransform: 'uppercase',
              fontWeight: 'bold',
              marginTop: '10px',
              display: 'flex',
              padding: '0 8px 5px 8px',
            }}
          >
            {!isPreview && (
              <>
                <div style={{ flex: 1, display: 'flex' }}>
                  {selections.size > 0 ? (
                    <>
                      <ButtonWithDropDown
                        className={buttonStyleClass}
                        dropDownChildren={
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(3, 1fr)',
                              gridGap: 10,
                              color: 'white',
                              backgroundColor: 'rgb(38, 38, 38)',
                              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
                              borderRadius: 4,
                              padding: 10,
                            }}
                          >
                            {colorChoices.map(e => (
                              <button
                                key={e}
                                style={{
                                  display: 'inline-block',
                                  border: '2px solid transparent',
                                  width: 14,
                                  height: 14,
                                  backgroundColor: e,
                                  borderRadius: 10,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  changeColor(e);
                                }}
                              ></button>
                            ))}
                          </div>
                        }
                      >
                        <img
                          style={{
                            width: 16,
                            height: 16,
                          }}
                          alt="Change colour" // TODO: i18n
                          src={icon(themeLabel).colorPicker}
                        />
                      </ButtonWithDropDown>

                      <ButtonWithDropDown
                        className={buttonStyleClass}
                        dropDownChildren={
                          <div
                            className={css`
                              display: grid;
                              grid-template-columns: repeat(2, 1fr);
                              grid-gap: 10px;
                              color: white;
                              background-color: rgb(38, 38, 38);
                              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
                              border-radius: 4px;
                              padding: 10px;

                              & > button {
                                display: inline-block;
                                border: none;
                                width: 14;
                                height: 14;
                                background: none;
                                cursor: pointer;
                              }
                            `}
                          >
                            <button
                              onClick={() => {
                                changeShape('circle');
                              }}
                            >
                              <img src={icon(themeLabel).circle} alt="Circle" />
                            </button>
                            <button
                              onClick={() => {
                                changeShape('triangle');
                              }}
                            >
                              <img
                                src={icon(themeLabel).triangle}
                                alt="Triangle"
                              />
                            </button>
                            <button
                              onClick={() => {
                                changeShape('square');
                              }}
                            >
                              <img src={icon(themeLabel).square} alt="Square" />
                            </button>
                          </div>
                        }
                      >
                        <img
                          style={{
                            width: 16,
                            height: 16,
                          }}
                          alt="Change shape"
                          src={icon(themeLabel).shape}
                        />
                      </ButtonWithDropDown>

                      <div>
                        <button
                          className={buttonStyleClass}
                          onClick={onDeleteSelectedEntities}
                        >
                          <img
                            style={{
                              width: 16,
                              height: 16,
                            }}
                            alt="Add performer"
                            src={icon(themeLabel).trash}
                          />
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
                
                <div>
                  <button className={buttonStyleClass} onClick={onAddEntity}>
                    <img
                      style={{
                        width: 16,
                        height: 16,
                      }}
                      alt="Add performer"
                      src={icon(themeLabel).performerAdd}
                    />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div
        className={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          background-color: ${theme.sidebar.tabs.background};
          overflow: auto;
        `}
      >
        <div
          ref={performersListRef}
          className={css`
            > div {
              display: flex;
              padding: 10px 10px 10px 10px;
              font-size: 0.75em;
              cursor: pointer;

              &.active {
                // TODO: use theme
                background-color: rgb(47, 51, 62);
              }

              &:hover {
                // TODO: use theme
                background-color: rgba(47, 51, 62, 0.5);

                &.active {
                  // TODO: use theme
                  background-color: rgb(56, 61, 74);
                }
              }

              & > div:first-child {
                width: 50px;
              }

              & > div:nth-child(2) {
                margin-top: 3px;
              }
            }

            overflow-y: scroll;
          `}
        >
          {[...entities]
            .filter(([, { name }]) => fuzzySearch(performersQuery, name))
            .map(([id, { name, color, shape }]) => (
              <div
                key={id}
                className={selections.has(id) ? 'active' : undefined}
                onClick={e => {
                  if (selections.has(id)) {
                    selectionsChanged(
                      new Set([...selections].filter(s => s !== id))
                    );
                  } else {
                    if (e.ctrlKey || e.metaKey) {
                      const newSelectedEntities = new Set([...selections, id]);
                      shiftKeyStartRef.current = id;

                      selectionsChanged(newSelectedEntities);
                    } else if (e.shiftKey) {
                      if (shiftKeyStartRef.current) {
                        const entitiesList = [...entities];
                        const selectedIndex = entitiesList.findIndex(
                          ([k]) => k === id
                        );
                        const startingIndex = entitiesList.findIndex(
                          ([k]) => k === shiftKeyStartRef.current
                        );
                        const start = Math.min(selectedIndex, startingIndex);
                        const end = Math.max(selectedIndex, startingIndex);

                        const newSelectedEntities = new Set<string>(); // Record<string, Entity> = {};

                        const e = [...entities];
                        for (let i = start; i <= end; i++) {
                          newSelectedEntities.add(e[i][0]);
                        }
                        selectionsChanged(newSelectedEntities);
                      } else {
                        shiftKeyStartRef.current = id;
                        selectionsChanged(new Set([id]));
                      }
                    } else {
                      shiftKeyStartRef.current = id;
                      selectionsChanged(new Set([id]));
                    }
                  }
                }}
              >
                <div
                  style={{
                    paddingTop: 6,
                  }}
                >
                  <PerformerIcon backgroundColor={color} shape={shape} />
                </div>
                <div>
                  {isPreview ? (
                    <span
                      style={{
                        fontSize: 13.3,
                        padding: '6px 10px 2px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {name}
                    </span>
                  ) : (
                    <EditableLabel
                      value={name}
                      onEnterPress={() => {
                        if (!performersListRef.current) return;

                        const inputs = Array.from(
                          performersListRef.current.querySelectorAll('input')
                        );

                        const index = inputs.findIndex(
                          el => el === document.activeElement
                        );

                        if (inputs.length === index + 1) {
                          (document.activeElement as HTMLInputElement).blur?.();
                        }

                        inputs[index + 1].focus();
                      }}
                      onSubmit={value => {
                        changeName(id, value);
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <AcknowledgeModal
        title={'How To Edit Performers'}
        handleOnClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      >
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/ocDs5xCJNmg?si=zZRCfe8mAJqfh2m6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </AcknowledgeModal>
    </div>
  );
};

export default PerformersTab;
