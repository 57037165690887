import { css } from '@emotion/css';
import React, {
  TouchEventHandler,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { array } from 'vectorious';
import { LogarithmicValue } from '../../lib/logarithmic-value';
import {
  add as add2,
  distance as distance2,
  equals as equals2,
  hadamard as hadamard2,
  scalarMul as scalarMul2,
  sub as sub2,
  Vector2,
} from '../../lib/vector2';
import { start } from '../../lib/pipe';
import { scale2D, translate2D } from '../../lib/matrix';
import { SvgWrapper, SvgWrapperObject } from '../SvgWrapper';
import {
  HUMAN_HEIGHT,
  MAX_ENTITY_DIAMETER,
  MIN_ENTITY_DIAMETER,
  PIXELS_PER_FEET,
  PIXElS_PER_METER,
} from '../constants';
import {
  joinPlacements,
  FormationHelpers,
  Performance,
} from '../performance-project';
import { getKV } from '../../lib/iterable-helpers';
import { getCurrentFormationIndex, TimelineState } from '../timeline-state';
import { useMouseUp } from '../../hooks/use-mouse-up';
import { ThemeContext } from '../../contexts/theme';
import Hammer from 'hammerjs';
import { initial } from 'lodash';

const MIN_ENTITY_RADIUS = MIN_ENTITY_DIAMETER / 2;
const MAX_ENTITY_RADIUS = MAX_ENTITY_DIAMETER / 2;

const measurementToPixelSpace = (measurement: Measurement): number => {
  switch (measurement.type) {
    case 'FEET':
      return parseFloat(measurement.value) * PIXELS_PER_FEET;
    case 'METERS':
      return parseFloat(measurement.value) * PIXElS_PER_METER;
  }
};

// const CIRCLE_RADIUS = (PIXELS_PER_FEET * (16 / 12)) / 2;
const defaultTickSpacing = PIXElS_PER_METER;

const toLin = (v: number) => LogarithmicValue.logarithmic(v).linear;
const toLog = (v: number) => LogarithmicValue.linear(v).logarithmic;

// Modulo in JavaScript has a weird quirk. This is a workaround.
export function modulo(a: number, m: number) {
  return ((a % m) + m) % m;
}

// Straight up stolen from here https://stackoverflow.com/a/14415822/538570
const wrap = (x: number, a: number, b: number): number =>
  a > b ? wrap(x, b, a) : modulo(x - a, b - a) + a;

type Camera = {
  position: [number, number];
  zoom: LogarithmicValue;
};

export type Entity = {
  color: string;
  name: string;
  shape: Shape;
};

const Circle = ({
  stroke,
  fill,
  strokeWidth,
  cx,
  cy,
  r,
}: {
  stroke: string;
  fill: string;
  strokeWidth: string | number;
  cx: number;
  cy: number;
  r: number;
}) => (
  <circle
    cx={cx}
    cy={cy}
    r={r}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    paintOrder={'stroke'}
  />
);

const Triangle = ({
  stroke,
  fill,
  strokeWidth,
  cx,
  cy,
  r,
}: {
  stroke: string;
  fill: string;
  strokeWidth: string | number;
  cx: number;
  cy: number;
  r: number;
}) => {
  const top = [cx, cy - r].join(',');
  const bottomLeft = [cx + r, cy + r].join(',');
  const bottomRight = [cx - r, cy + r].join(',');

  return (
    <polygon
      points={`${top} ${bottomLeft} ${bottomRight}`}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      paintOrder={'stroke'}
    />
  );
};

const Square = ({
  stroke,
  fill,
  strokeWidth,
  cx,
  cy,
  r,
}: {
  stroke: string;
  fill: string;
  strokeWidth: string | number;
  cx: number;
  cy: number;
  r: number;
}) => (
  <rect
    width={`${r * 2}`}
    height={`${r * 2}`}
    x={cx - r}
    y={cy - r}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    paintOrder={'stroke'}
  />
);

const Shape = ({
  stroke,
  fill,
  strokeWidth,
  cx,
  cy,
  r,
  shape,
}: {
  stroke: string;
  fill: string;
  strokeWidth: string | number;
  cx: number;
  cy: number;
  r: number;
  shape: Shape;
}): JSX.Element | null => {
  switch (shape) {
    case 'circle':
      return (
        <Circle
          stroke={stroke}
          fill={fill}
          strokeWidth={strokeWidth}
          cx={cx}
          cy={cy}
          r={r}
        />
      );
    case 'triangle':
      return (
        <Triangle
          stroke={stroke}
          fill={fill}
          strokeWidth={strokeWidth}
          cx={cx}
          cy={cy}
          r={r}
        />
      );
    case 'square':
      return (
        <Square
          stroke={stroke}
          fill={fill}
          strokeWidth={strokeWidth}
          cx={cx}
          cy={cy}
          r={r}
        />
      );
    default:
      return null;
  }
};

function getInitials(str: string) {
  const chars = str
    .split(' ')
    .map(s => s[0])
    .join('');
  if (chars.length === 1) {
    return str.slice(0, 2);
  }
  return chars.slice(0, 2);
}

const EntityObject = ({
  isSelected,
  x,
  y,
  r,
  color,
  shape,
  name,
}: {
  isSelected: boolean;
  x: number;
  y: number;
  r: number;
  color: string;
  shape: Shape;
  name: string;
}) => {
  const radiusRatio =
    (r - MIN_ENTITY_RADIUS) / (MAX_ENTITY_RADIUS - MIN_ENTITY_RADIUS);

  return (
    <g>
      {isSelected ? (
        <Shape
          stroke="black"
          fill="white"
          strokeWidth={`${radiusRatio * 0.5 + 0.5}`}
          cx={x}
          cy={-y - (shape === 'triangle' ? 1.5 : 0)}
          r={r + 2 + (shape === 'triangle' ? 1.5 : 0)}
          shape={shape}
        />
      ) : null}
      <Shape
        fill={color}
        stroke={color}
        strokeWidth={3}
        cx={x}
        cy={-y}
        r={r}
        shape={shape}
      />
      <text
        x={`${x}`}
        y={`${-y + 0.5}`}
        stroke={'black'}
        strokeWidth={`${radiusRatio + 1}`}
        fill={'white'}
        fontSize={`${radiusRatio * 0.6 + 0.2}em`}
        dominantBaseline="middle"
        textAnchor="middle"
        paintOrder={'stroke'}
      >
        {getInitials(name)}
      </text>
      <text
        x={`${x}`}
        y={`${-y + 10.5}`}
        stroke={'black'}
        strokeWidth={`${radiusRatio + 1}`}
        fill={'white'}
        fontSize={`${radiusRatio * 0.6 + 0.2}em`}
        dominantBaseline="middle"
        textAnchor="middle"
        paintOrder={'stroke'}
      >
        {name}
      </text>
    </g>
  );
};

const Marker = ({ id, color }: { id: string; color: string }) => (
  <defs>
    <marker
      id={`arrowhead-${btoa(id)}`}
      markerWidth="10"
      markerHeight="7"
      refX="0"
      refY="3.5"
      orient="auto"
    >
      {/* <polygon points="0 0, 3 3.5, 0 7" fill={color} /> */}
      <path
        d="M0 0 L3 3.5 L0 7"
        stroke={color}
        fill="transparent"
        strokeWidth={0.7}
      />
    </marker>
  </defs>
);

const StraightPath = ({
  id,
  color,
  from: [x1, y1],
  to: [x2, y2],
}: {
  id: string;
  color?: string;
  from: Vector2;
  to: Vector2;
}) => (
  <>
    <Marker id={id} color={color ?? 'black'} />
    <path
      d={`M ${x1} ${-y1} L ${(x1 + x2) / 2} ${-(y1 + y2) / 2} L ${x2} ${-y2}`}
      stroke={color ?? 'black'}
      strokeWidth={0.7}
      fill="transparent"
      markerMid={`url(#arrowhead-${btoa(id)})`}
    />
  </>
);

type EditorProps = {
  isPreview: boolean;
  performance: Performance;
  selections: Iterable<string>;
  onPositionsChange?: (
    changes: Iterable<[string, Vector2]>,
    formationIndex: number
  ) => void;
  onSelectionsChange?: (changes: Iterable<string>) => void;
  onFormationIndexChange?: (newIndex: number) => void;
  style?: React.CSSProperties | undefined;

  timelineState: TimelineState;
  showGrid: boolean;
  showPaths: boolean;
  snapGrid: boolean;
  entityDiameter: number;
};

// TODO: memoize the value of entities and selections.
// TODO: handle the edge case where there are no formations
// TODO: this code is beginning to look really ugly. Time to refactor things
// NOTE: is there too much dependence on the concept of a "project"?
//   Maybe there is. But one benefit of being given access to a project is that
//   it gives the editor some flexibility
export const Editor = ({
  isPreview,
  onPositionsChange,
  onSelectionsChange,
  onFormationIndexChange,
  performance,
  selections,
  style,
  // currentFormationIndex,
  timelineState,
  showGrid,
  showPaths,
  snapGrid,
  entityDiameter,
}: EditorProps) => {
  const [camera, updateCamera] = useReducer<
    (state: Camera, partialState: Partial<Camera>) => Camera
  >((state, partialState) => ({ ...state, ...partialState }), {
    zoom: LogarithmicValue.logarithmic(0.5),
    position: [0, 0],
  });
  const selectionsSet = new Set(selections);
  const { onMouseDown: mouseDown, onMouseUp: mouseUp } = useMouseUp();
  const { theme } = useContext(ThemeContext);
  const parentDivRef = useRef<HTMLDivElement>(null);

  const currentFormationIndex = getCurrentFormationIndex(
    performance,
    timelineState
  );

  const currentFormation = performance.getFormationByIndex(
    currentFormationIndex
  );

  useEffect(() => {
    if (parentDivRef.current) {
      let initialTouchX = 0;
      let initialTouchY = 0;

      const hammer = new Hammer(parentDivRef.current);

      hammer.get('pinch').set({ enable: true });
      hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

      let initialZoom = 0;

      hammer.on('pinchstart', e => {
        if (e.pointerType !== 'touch') return;
        initialZoom = camera.zoom.logarithmic;
      });

      hammer.on('pinch', e => {
        if (e.pointerType !== 'touch') return;
        updateCamera({
          zoom: LogarithmicValue.logarithmic(initialZoom * e.scale),
          position: add2(camera.position, [
            -e.deltaX / camera.zoom.linear,
            e.deltaY / camera.zoom.linear,
          ]),
        });
      });

      hammer.on('panstart', e => {
        if (e.pointerType !== 'touch') return;
        // Store the initial touch position
        if (e.pointers.length === 1) {
          initialTouchX = e.center.x;
          initialTouchY = e.center.y;
        }
      });

      hammer.on('pan', e => {
        if (e.pointerType !== 'touch') return;
        // Calculate the delta values based on the difference between the current touch position
        // and the initial touch position
        if (e.pointers.length === 1) {
          const deltaX = e.center.x - initialTouchX;
          const deltaY = e.center.y - initialTouchY;
          updateCamera({
            position: add2(camera.position, [
              -deltaX / camera.zoom.linear,
              deltaY / camera.zoom.linear,
            ]),
          });
        }
      });

      hammer.on('panend', e => {
        if (e.pointerType !== 'touch') return;
        if (e.pointers.length === 1) {
          camera.position = add2(camera.position, [
            -e.deltaX / camera.zoom.linear,
            e.deltaY / camera.zoom.linear,
          ]);
        }
      });

      return () => {
        hammer.destroy();
      };
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      update();
    });

    if (parentDivRef.current) {
      resizeObserver.observe(parentDivRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const mutablePlacements = useRef(new Map());

  useEffect(() => {
    const newPlacements = new Map([...currentFormation.placements]);
    setLocalPlacements([...newPlacements]); // Update state
    mutablePlacements.current = newPlacements; // Update mutable reference
}, [performance, currentFormationIndex]);

  //const mutablePlacements = useRef(new Map(localPlacements));

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === '=' || e.key === '-')) {
        e.preventDefault();
        e.stopPropagation();
        updateCamera({
          zoom: LogarithmicValue.logarithmic(
            camera.zoom.logarithmic + (e.key === '=' ? 1 : -1)
          ),
        });
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [camera]);

  const drawingAreaRef = useRef<SvgWrapperObject | null>(null);
  const mousePositionRef = useRef<Vector2>([0, 0]);
  const touchPositionRef1 = useRef<Vector2>([0, 0]);
  const touchPositionRef2 = useRef<Vector2>([0, 0]);

  const [, update] = useReducer(() => ({}), {});

  type MouseState =
    | {
        type: 'NOTHING';
      }
    | {
        type: 'MOUSE_DOWN';
        event:
          | {
              type: 'ITEM';
              // Some boolean flag to determine whether the item being clicked
              // was previously selected. useful information to determine
              // whether to deselect the item when the user mouses up
              wasPreviouslySelected: boolean;
              id: string;
              startingPosition: Vector2;
            }
          | {
              type: 'BLANK_SPACE';
              startPosition: Vector2;
            };
        hasMoved: boolean;
      };

  const [mouseState, setMouseState] = useState<MouseState>({
    type: 'NOTHING',
  });
  const [localPlacements, setLocalPlacements] = useState<
    [string, EntityPlacement][]
  >([...currentFormation.placements]);

  // This thing exists in order to destroy React's virtual DOM structure
  // associated with the SVG and all the SVG elements. This is to help with
  // performance. Not sure of React's implementation details, but I suspect
  // that they are just adding stuff to the underlying VDOM structure, causing
  // massive amounts of iteration to occur in order to reconcile the VDOM with
  // the DOM
  const [isEditorHidden, setIsEditorHidden] = useState(false);

  // To keep things snappy
  useEffect(() => {
    setIsEditorHidden(true);
    const timeout = setTimeout(() => {
      setIsEditorHidden(false);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [timelineState.mode]);

  function combineEntityPlacements(
    formation: FormationHelpers
  ): Iterable<[string, { entity: Entity; placement: EntityPlacement }]> {
    const getEntity = (id: string): EntityPlacement =>
      getKV(formation.placements, id) ?? {
        position: [0, 0] as Vector2,
      };

    return new Map(
      [...performance.entities].map(([id, entity]) => [
        id,
        { entity, placement: getEntity(id) },
      ])
    );
  }

  function combineEntityPlacementAtTime(
    formation: FormationHelpers,
    time: number
  ): Iterable<[string, { entity: Entity; placement: EntityPlacement }]> {
    const getEntity = (id: string): EntityPlacement => {
      const endTime = performance.getEndTimeAtFormationIndex(
        performance.formations.length - 1
      );
      if (endTime === undefined)
        return {
          position: [0, 0] as Vector2,
        };
      return (
        getKV(formation.getPlacementAtTime(Math.min(time, endTime)), id) ?? {
          position: [0, 0] as Vector2,
        }
      );
    };

    return new Map(
      [...performance.entities].map(([id, entity]) => [
        id,
        { entity, placement: getEntity(id) },
      ])
    );
  }

const entityMouseUp = (i: string) => {
  if (selectionsSet.has(i)) {
    if (mouseState.type === 'MOUSE_DOWN') {
      if (!mouseState.hasMoved) {
        const { event } = mouseState;
        if (event.type === 'ITEM') {
          if (event.wasPreviouslySelected) {
            const s = new Set(selections);
            s.delete(i);
            onSelectionsChange?.(s);
          }
        }
      } else if (mouseState.hasMoved) {
        const updatedPlacements = [...selectionsSet].map(id => {
          const placement = mutablePlacements.current.get(id);
          if (!placement) return null;

          const position = snapGrid && showGrid
            ? snapToGrid(placement.position)
            : placement.position;

          mutablePlacements.current.set(id, { ...placement, position });
          return [id, position] as [string, Vector2];
        }).filter(Boolean);

        // Notify parent or state of the position changes
        onPositionsChange?.(updatedPlacements, currentFormationIndex);

        // Sync state once after modifications
        setLocalPlacements([...mutablePlacements.current.entries()]);
      }
    }
  }
};

  const deactivateAllEntities = () => {
    onSelectionsChange?.([]);
  };

  const getDrawingAreaDimensions = () => {
    const svg = drawingAreaRef.current;
    const clientRect = svg
      ? svg.getBoundingClientRect()
      : { width: 1, height: 1 };
    return [clientRect.width, clientRect.height] as Vector2;
  };

  const getTransform = () => {
    const drawingAreaDimensions = getDrawingAreaDimensions();
    return translate2D(hadamard2(drawingAreaDimensions, [0.5, 0.5]))
      .multiply(scale2D([1, -1]))
      .multiply(translate2D(scalarMul2(camera.position, -1)))
      .multiply(scale2D([camera.zoom.linear, camera.zoom.linear]));
  };

  const screenToSpace = (position: Vector2): Vector2 => {
    const svgDimensions = getDrawingAreaDimensions();

    const screenCenter = start(position)
      // Grab the center of the screen
      ._(pos => sub2(pos, scalarMul2(svgDimensions, 0.5)))

      // Flip the y axis
      ._(pos => hadamard2(pos, [1, -1])).value;

    return start(screenCenter)
      ._(pos => add2(pos, camera.position))
      ._(pos => scalarMul2(pos, 1 / camera.zoom.linear)).value;
  };

  const getCursorPosition = () => screenToSpace(mousePositionRef.current);

  const getViewportBounds = () => {
    const [width, height] = start(getDrawingAreaDimensions())._(d =>
      scalarMul2(d, 1 / camera.zoom.linear)
    ).value;

    // In the real space, pixels move at a rate proportional to the zoom. So
    // it's best to divide the pixel position by the zoom
    const left = camera.position[0] / camera.zoom.linear - width / 2;
    const top = camera.position[1] / camera.zoom.linear + height / 2;
    const right = camera.position[0] / camera.zoom.linear + width / 2;
    const bottom = camera.position[1] / camera.zoom.linear - height / 2;

    return { width, height, left, top, right, bottom };
  };

  const getEntityUnderCursor = useCallback(():
    | [string, EntityPlacement, number]
    | null => {
    const cursorPosition = getCursorPosition();

    const radius = entityDiameter / 2;

    for (const [, [id, placement]] of localPlacements.entries()) {
      if (distance2(cursorPosition, placement.position) < radius) {
        return [id, placement, currentFormationIndex];
      }
    }

    if (!showPaths) return null;

    const previousFormationIndex = currentFormationIndex - 1;
    const previousFormation = performance.getFormationByIndex(
      previousFormationIndex
    );
    if (previousFormation.exists) {
      for (const [id, placement] of previousFormation.placements) {
        if (distance2(cursorPosition, placement.position) < radius) {
          return [id, placement, previousFormationIndex];
        }
      }
    }

    const nextFormationIndex = currentFormationIndex + 1;
    const nextFormation = performance.getFormationByIndex(nextFormationIndex);

    if (nextFormation.exists) {
      for (const [id, placement] of nextFormation.placements) {
        if (distance2(cursorPosition, placement.position) < radius) {
          return [id, placement, nextFormationIndex];
        }
      }
    }

    return null;
  }, [currentFormationIndex, localPlacements, getCursorPosition]);

  const onMouseUp = useCallback(
    mouseUp(() => {
      if (isPreview) {
        return;
      }

      const indexAndEntity = getEntityUnderCursor();

      if (indexAndEntity) {
        const [index] = indexAndEntity;
        entityMouseUp(index);
      }

      setMouseState({ type: 'NOTHING' });
    }),
    [getEntityUnderCursor]
  );

  const onMouseDown = useCallback(
    mouseDown(() => {
      if (isPreview) {
        return;
      }

      const idAndEntity = getEntityUnderCursor();
      if (idAndEntity) {
        const [id, , formationIndex] = idAndEntity;
        setMouseState({
          type: 'MOUSE_DOWN',
          event: {
            type: 'ITEM',
            id,
            wasPreviouslySelected: selectionsSet.has(id),
            startingPosition: getCursorPosition(),
          },
          hasMoved: false,
        });
        onSelectionsChange?.([...selectionsSet, id]);
        if (formationIndex !== currentFormationIndex) {
          onFormationIndexChange?.(formationIndex);
          setLocalPlacements([
            ...performance.getFormationByIndex(formationIndex).placements,
          ]);
        }
        return;
      } else {
        deactivateAllEntities();
        setMouseState({
          type: 'MOUSE_DOWN',
          event: {
            type: 'BLANK_SPACE',
            startPosition: mousePositionRef.current,
          },
          hasMoved: false,
        });
      }
    }),
    [getEntityUnderCursor]
  );

  // function calculateDistance(touch1: Touch, touch2: Touch) {
  //   const deltaX = touch1.clientX - touch2.clientX;
  //   const deltaY = touch1.clientY - touch2.clientY;
  //   return Math.hypot(deltaX, deltaY);
  // }

  const initialDistanceRef = useRef(0);
  const initialZoomRef = useRef(camera.zoom.logarithmic);

  // // Touch start event handler for pinch
  // const onTouchStart = (event: TouchEvent) => {
  //   if (event.touches.length === 2) {
  //     const [touch1, touch2] = event.touches;
  //     initialDistanceRef.current = calculateDistance(touch1, touch2);
  //     initialZoomRef.current = camera.zoom.logarithmic;
  //   }
  // };

  // // Touch move event handler for pinch
  // const onTouchMove: TouchEventHandler<HTMLDivElement> = (event) => {
  //   const touches = event.touches;

  //   if (touches.length === 2 && initialDistanceRef.current !== 0) {
  //     const touch1 = touches[0];
  //     const touch2 = touches[1];

  //     const currentDistance = calculateDistance(touch1, touch2);
  //     const scaleChange = currentDistance / initialDistanceRef.current;

  //     // Calculate the new zoom level
  //     const newZoom = LogarithmicValue.logarithmic(
  //       initialZoomRef.current * scaleChange
  //     );

  //     // Update the camera zoom
  //     updateCamera({ zoom: newZoom });
  //   }
  // };

  // // Touch end event handler for pinch
  // const onTouchEnd = () => {
  //   initialDistanceRef.current = 0;
  // };

  const blankSpaceSelection = (startPosition: Vector2) => {
    const topLeft = screenToSpace([
      Math.min(startPosition[0], mousePositionRef.current[0]),
      Math.min(startPosition[1], mousePositionRef.current[1]),
    ]);

    const bottomRight = screenToSpace([
      Math.max(startPosition[0], mousePositionRef.current[0]),
      Math.max(startPosition[1], mousePositionRef.current[1]),
    ]);

    onSelectionsChange?.(
      localPlacements
        .filter(([, c]) => {
          return (
            c.position[0] > topLeft[0] &&
            c.position[0] < bottomRight[0] &&
            c.position[1] < topLeft[1] &&
            c.position[1] > bottomRight[1]
          );
        })
        .map(([id]) => id)
    );

    return;
  };

const moveEvent = ([dx, dy]: Vector2) => {
  if (mouseState.type === 'MOUSE_DOWN') {
    setMouseState({ ...mouseState, hasMoved: true });

    if (mouseState.event.type === 'BLANK_SPACE') {
      blankSpaceSelection(mouseState.event.startPosition);
    } else {
      const delta = scalarMul2([dx, -dy], 1 / camera.zoom.linear);

      if (!mouseState.event.wasPreviouslySelected) {
        // Move a single entity
        deactivateAllEntities();
        onSelectionsChange?.([mouseState.event.id]);

        const entityId = mouseState.event.id;
        const oldPlacement = mutablePlacements.current.get(entityId);

        if (oldPlacement) {
          const newPosition = add2(oldPlacement.position, delta);
          mutablePlacements.current.set(entityId, {
            ...oldPlacement,
            position: newPosition,
          });
        }
      } else {
        // Move all selected entities
        selectionsSet.forEach(id => {
          const oldPlacement = mutablePlacements.current.get(id);

          if (oldPlacement) {
            const newPosition = add2(oldPlacement.position, delta);
            mutablePlacements.current.set(id, {
              ...oldPlacement,
              position: newPosition,
            });
          }
        });
      }

      // Batch update state after all calculations
      requestAnimationFrame(() => {
        setLocalPlacements([...mutablePlacements.current.entries()]);
      });
    }
  }
};

  const [width, height] = getDrawingAreaDimensions();

  const mat = `translate(${-camera.position[0]}, ${
    camera.position[1]
  }) translate(${width / 2}, ${height / 2}) scale(${camera.zoom.linear})`;

  const editorStyle = { ...style, background: theme.stage.background };

  const onWheel = useCallback(
    (e: WheelEvent) => {
      const [x, y] = getDrawingAreaDimensions();
      const dimensions = [x, y] as Vector2;

      if (e.ctrlKey) {
        const newZoom = camera.zoom.addLogarithmic(-e.deltaY * 0.01);

        const cursorCenter = start(mousePositionRef.current)
          ._(pos => sub2(pos, scalarMul2(dimensions, 0.5)))
          ._(pos => hadamard2(pos, [1, -1])).value;

        const newCameraPosition = start(cursorCenter)
          ._(pos => add2(pos, camera.position))
          ._(pos => scalarMul2(pos, newZoom.linear / camera.zoom.linear))
          ._(pos => sub2(pos, cursorCenter)).value;

        updateCamera({
          zoom: newZoom,
          position: newCameraPosition,
        });
      } else {
        const delta = [e.deltaX, -e.deltaY] as Vector2;

        updateCamera({
          position: add2(camera.position, delta),
        });
      }
    },
    [camera]
  );

  return (
    <div
      ref={parentDivRef}
      style={editorStyle}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      // onTouchStart={e => {
      //   e.stopPropagation();
      //   if (e.touches.length === 1) {
      //     const touch = e.touches[0];
      //     touchPositionRef1.current = [touch.clientX, touch.clientY];
      //   }
      //   if (e.touches.length === 2) {
      //     const touch1 = e.touches[0];
      //     const touch2 = e.touches[1];
      //     const currentDistance = distance2(
      //       [touch1.clientX, touch1.clientY],
      //       [touch2.clientX, touch2.clientY]
      //     );
      //     initialDistanceRef.current = currentDistance;
      //     initialZoomRef.current = camera.zoom.logarithmic;
      //   }
      // }}
      // onTouchMove={e => {
      //   e.stopPropagation();
      //   if (e.touches.length === 1) {
      //     const touch = e.touches[0];

      //     const currentTouchPos: Vector2 = [touch.clientX, touch.clientY];

      //     // Calculate the delta between the current touch position and the previous touch position
      //     const deltaX = currentTouchPos[0] - touchPositionRef1.current[0];
      //     const deltaY = currentTouchPos[1] - touchPositionRef1.current[1];

      //     // Update the previous touch position
      //     touchPositionRef1.current = currentTouchPos;

      //     // Use deltaX and deltaY for your touch-related calculations
      //     const delta = [-deltaX, deltaY] as Vector2;

      //     updateCamera({
      //       position: add2(camera.position, delta),
      //     });
      //   } else if (e.touches.length === 2) {
      //     const touch1 = e.touches[0];
      //     const touch2 = e.touches[1];

      //     const currentDistance = distance2(
      //       [touch1.clientX, touch1.clientY],
      //       [touch2.clientX, touch2.clientY]
      //     );
      //     const scaleChange = currentDistance / initialDistanceRef.current;

      //     // Calculate the new zoom level
      //     const newZoom = LogarithmicValue.logarithmic(
      //       initialZoomRef.current * scaleChange
      //     );

      //     // Update the camera zoom
      //     updateCamera({ zoom: newZoom });
      //   }
      // }}
      onWheel={onWheel}
      onMouseMove={({ clientX, clientY, ...e }) => {
        const x = e.pageX - e.currentTarget.offsetLeft;
        const y = e.pageY - e.currentTarget.offsetTop;

        const newMousePosition: Vector2 = [x, y];

        if (!equals2(newMousePosition, mousePositionRef.current)) {
          mousePositionRef.current = newMousePosition;

          moveEvent([e.movementX, e.movementY]);
        }
      }}
    >
      {(() =>
        isEditorHidden ? (
          <div></div>
        ) : (
          <SvgWrapper
            style={{
              height: '100%',
            }}
            ref={ref => {
              if (ref === null) {
                return;
              }
              const currentRef = drawingAreaRef.current;
              drawingAreaRef.current = ref;

              if (currentRef === ref) return;

              update();
            }}
            className={css`
              display: block;
              box-sizing: border-box;
              width: 100%;
              text {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
              }
            `}
          >
            <>
              {performance.markers
                ? performance.markers.map((marker, i) => {
                    if (marker.type === 'IMAGE') {
                      return (
                        <g transform={mat} key={i}>
                          <image
                            href={marker.url}
                            width={measurementToPixelSpace(marker.width)}
                            height={measurementToPixelSpace(marker.height)}
                            x={
                              -(measurementToPixelSpace(marker.width) / 2) +
                              measurementToPixelSpace(marker.x)
                            }
                            y={
                              -(measurementToPixelSpace(marker.height) / 2) -
                              measurementToPixelSpace(marker.y)
                            }
                            preserveAspectRatio="none"
                          />
                        </g>
                      );
                    }
                  })
                : null}

              {(() => {
                if (!showGrid) return;
                // Horizontal lines.

                const [width, height] = getDrawingAreaDimensions();
                const { left } = getViewportBounds();

                const tickSpacing =
                  ((defaultTickSpacing *
                    toLin(
                      wrap(
                        camera.zoom.logarithmic,
                        toLog(PIXElS_PER_METER),
                        toLog(PIXElS_PER_METER * 5)
                      )
                    )) /
                    20) *
                  4;

                const opacity =
                  ((wrap(
                    camera.zoom.logarithmic,
                    toLog(PIXElS_PER_METER),
                    toLog(PIXElS_PER_METER * 5)
                  ) -
                    toLog(PIXElS_PER_METER)) /
                    (toLog(PIXElS_PER_METER * 5) - toLog(PIXElS_PER_METER))) **
                  2;

                const count = Math.ceil(height / tickSpacing) + 2;

                const [, startY] = start([left, 0])._(([x, y]) =>
                  (
                    getTransform()
                      .multiply(array([[x], [y], [1]]))
                      .toArray() as number[][]
                  ).flat()
                ).value;

                return Array.from({ length: count }).map((_, i) => (
                  <g key={i}>
                    {Array.from({ length: 5 }).map((_, j) => (
                      <path
                        key={j}
                        d={`M${0} ${
                          (i + j / 5) * tickSpacing +
                          (startY % tickSpacing) -
                          tickSpacing
                        } H ${width}`}
                        stroke={
                          j === 0
                            ? 'rgba(74, 74, 74, 1)'
                            : `rgba(74, 74, 74, ${opacity})`
                        }
                      />
                    ))}
                  </g>
                ));
              })()}

              {(() => {
                // Vertical lines.
                if (!showGrid) return;

                const [width, height] = getDrawingAreaDimensions();
                const { top } = getViewportBounds();

                const tickSpacing =
                  ((defaultTickSpacing *
                    toLin(
                      wrap(
                        camera.zoom.logarithmic,
                        toLog(PIXElS_PER_METER),
                        toLog(PIXElS_PER_METER * 5)
                      )
                    )) /
                    20) *
                  4;

                const opacity =
                  ((wrap(
                    camera.zoom.logarithmic,
                    toLog(PIXElS_PER_METER),
                    toLog(PIXElS_PER_METER * 5)
                  ) -
                    toLog(PIXElS_PER_METER)) /
                    (toLog(PIXElS_PER_METER * 5) - toLog(PIXElS_PER_METER))) **
                  2;

                const count = Math.ceil(width / tickSpacing) + 2;

                const [startX] = start([0, top])._(([x, y]) =>
                  (
                    getTransform()
                      .multiply(array([[x], [y], [1]]))
                      .toArray() as number[][]
                  ).flat()
                ).value;

                return Array.from({ length: count }).map((_, i) => (
                  <g key={i}>
                    {Array.from({ length: 5 }).map((_, j) => (
                      <path
                        key={j}
                        d={`M${
                          (i + j / 5) * tickSpacing +
                          (startX % tickSpacing) -
                          tickSpacing
                        } ${0} V ${height}`}
                        stroke={
                          j === 0
                            ? 'rgba(74, 74, 74, 1)'
                            : `rgba(74, 74, 74, ${opacity})`
                        }
                      />
                    ))}
                  </g>
                ));
              })()}

              {(() => {
                if (!showGrid) return;
                const { top, bottom } = getViewportBounds();

                const [startX, startY] = start([0, top])._(([x, y]) =>
                  (
                    getTransform()
                      .multiply(array([[x], [y], [1]]))
                      .toArray() as number[][]
                  ).flat()
                ).value;

                const [, endY] = start([0, bottom])._(([x, y]) =>
                  (
                    getTransform()
                      .multiply(array([[x], [y], [1]]))
                      .toArray() as number[][]
                  ).flat()
                ).value;

                return (
                  <path
                    d={`M${startX} ${startY} V ${endY}`}
                    stroke={'rgba(255, 255, 255, 0.25)'}
                  />
                );
              })()}
              {(() => {
                if (!showGrid) return;
                const { left, right } = getViewportBounds();

                const [startX, startY] = start([left, 0])._(([x, y]) =>
                  (
                    getTransform()
                      .multiply(array([[x], [y], [1]]))
                      .toArray() as number[][]
                  ).flat()
                ).value;

                const [endX] = start([right, 0])._(([x, y]) =>
                  (
                    getTransform()
                      .multiply(array([[x], [y], [1]]))
                      .toArray() as number[][]
                  ).flat()
                ).value;

                return (
                  <path
                    d={`M${startX} ${startY} H ${endX}`}
                    stroke={'rgba(255, 255, 255, 0.25)'}
                  />
                );
              })()}

              {(() => {
                const [startX, startY] = start([0, -10 / camera.zoom.linear])._(
                  ([x, y]) =>
                    (
                      getTransform()
                        .multiply(array([[x], [y], [1]]))
                        .toArray() as number[][]
                    ).flat()
                ).value;

                const [, endY] = start([0, 10 / camera.zoom.linear])._(
                  ([x, y]) =>
                    (
                      getTransform()
                        .multiply(array([[x], [y], [1]]))
                        .toArray() as number[][]
                    ).flat()
                ).value;

                return (
                  <path
                    d={`M${startX} ${startY} V ${endY}`}
                    stroke={'#B6FB44'}
                  />
                );
              })()}
              {(() => {
                const [startX, startY] = start([-10 / camera.zoom.linear, 0])._(
                  ([x, y]) =>
                    (
                      getTransform()
                        .multiply(array([[x], [y], [1]]))
                        .toArray() as number[][]
                    ).flat()
                ).value;

                const [endX] = start([10 / camera.zoom.linear, 0])._(([x, y]) =>
                  (
                    getTransform()
                      .multiply(array([[x], [y], [1]]))
                      .toArray() as number[][]
                  ).flat()
                ).value;

                return (
                  <path
                    d={`M${startX} ${startY} H ${endX}`}
                    stroke={'#B6FB44'}
                  />
                );
              })()}

              {performance.markers
                ? performance.markers.map((marker, i) => {
                    if (marker.type === 'V2_STAGE') {
                      return (
                        <g transform={mat} key={i}>
                          <rect
                            width={measurementToPixelSpace(marker.width) + 1}
                            height={measurementToPixelSpace(marker.depth) + 1}
                            x={
                              -(measurementToPixelSpace(marker.width) / 2) -
                              1 / 2
                            }
                            y={
                              -(measurementToPixelSpace(marker.depth) / 2) -
                              1 / 2
                            }
                            fill={'transparent'}
                            stroke="rgba(255, 255, 255, 0.5)"
                            strokeWidth={1}
                          />
                          <text
                            x={0}
                            y={
                              -(measurementToPixelSpace(marker.depth) / 2) - 10
                            }
                            fill={'rgba(255, 255, 255, 0.75)'}
                            textAnchor="middle"
                            fontFamily="'Helvetica neue', Helvetica, Arial, sans-serif"
                            fontSize={'0.4em'}
                          >
                            {marker.topLabel}
                          </text>

                          <text
                            x={0 * PIXElS_PER_METER}
                            y={measurementToPixelSpace(marker.depth) / 2 + 15}
                            fill={'rgba(255, 255, 255, 0.75)'}
                            textAnchor="middle"
                            fontFamily="'Helvetica neue', Helvetica, Arial, sans-serif"
                            fontSize={'0.4em'}
                          >
                            {marker.bottomLabel}
                          </text>

                          <text
                            fill={'rgba(255, 255, 255, 0.75)'}
                            textAnchor="middle"
                            transform={` translate(${
                              -measurementToPixelSpace(marker.width) / 2 - 15
                            }, ${0}) rotate(-90)`}
                            fontFamily="'Helvetica neue', Helvetica, Arial, sans-serif"
                            fontSize={'0.4em'}
                          >
                            {marker.leftLabel}
                          </text>

                          <text
                            fill={'rgba(255, 255, 255, 0.75)'}
                            textAnchor="middle"
                            transform={` translate(${
                              measurementToPixelSpace(marker.width) / 2 + 15
                            }, ${0}) rotate(90)`}
                            fontFamily="'Helvetica neue', Helvetica, Arial, sans-serif"
                            fontSize={'0.4em'}
                          >
                            {marker.rightLabel}
                          </text>
                        </g>
                      );
                    }
                  })
                : null}

              {(() => {
                if (timelineState.mode === 'SEEKER') {
                  return null;
                }

                if (!showPaths) return null;

                // The next formation
                const previousFormationIndex = currentFormationIndex - 1;
                if (previousFormationIndex < 0) {
                  return null;
                }

                const previousFormation = performance.getFormationByIndex(
                  previousFormationIndex
                );

                const directions = joinPlacements(previousFormation.placements)(
                  localPlacements
                );

                const { left, right, top, bottom } = getViewportBounds();

                // Helper function to check if an entity is within the viewport
                const isInViewport = (x: number, y: number) =>
                  x >= left && x <= right && y >= bottom && y <= top;

                return (
                  <g transform={mat} opacity={0.5}>
                    {[...directions]
                    .filter(
                      ([, { from: { position: [x1, y1] }, to: { position: [x2, y2] } }]) =>
                        isInViewport(x1, y1) || isInViewport(x2, y2) // Only include paths within the viewport
                    ).map(
                      ([
                        id,
                        {
                          from: {
                            position: [x1, y1],
                          },
                          to: {
                            position: [x2, y2],
                          },
                        },
                      ]) => {
                        return (
                          <StraightPath
                            key={id}
                            id={id}
                            color={getKV(performance.entities, id)?.color}
                            from={[x1, y1]}
                            to={[x2, y2]}
                          />
                        );
                      }
                    )}
                    {[
                      ...combineEntityPlacements(
                        performance.getFormationByIndex(previousFormationIndex)
                      ),
                    ]
                      .slice()
                      .reverse()
                      .filter(
                        ([, { placement: { position: [x, y] } }]) =>
                          isInViewport(x, y) // Only include entities within the viewport
                      )
                      .map(
                        (
                          [
                            id,
                            {
                              entity: { color, name, shape },
                              placement: {
                                position: [x, y],
                              },
                            },
                          ],
                          i
                        ) => {
                          return (
                            <EntityObject
                              key={i}
                              isSelected={selectionsSet.has(id)}
                              x={x}
                              y={y}
                              r={entityDiameter / 2}
                              color={color}
                              shape={shape}
                              name={name}
                            />
                          );
                        }
                      )}
                  </g>
                );
              })()}

              {(() => {
                if (timelineState.mode === 'SEEKER') {
                  return null;
                }

                if (!showPaths) return null;

                // The next formation
                const nextFormationIndex = currentFormationIndex + 1;
                if (nextFormationIndex >= performance.formationsCount) {
                  return null;
                }

                const nextFormation =
                  performance.getFormationByIndex(nextFormationIndex);

                const directions = joinPlacements(localPlacements)(
                  nextFormation.placements
                );

                return (
                  <g transform={mat} opacity={0.5}>
                    {[...directions].map(
                      ([
                        id,
                        {
                          from: {
                            position: [x1, y1],
                          },
                          to: {
                            position: [x2, y2],
                          },
                        },
                      ]) => {
                        return (
                          <StraightPath
                            id={id}
                            color={getKV(performance.entities, id)?.color}
                            key={id}
                            from={[x1, y1]}
                            to={[x2, y2]}
                          />
                        );
                      }
                    )}
                    {[
                      ...combineEntityPlacements(
                        performance.getFormationByIndex(nextFormationIndex)
                      ),
                    ]
                      .slice()
                      .reverse()
                      .map(
                        ([
                          id,
                          {
                            entity: { color, name, shape },
                            placement: {
                              position: [x, y],
                            },
                          },
                        ]) => {
                          return (
                            <EntityObject
                              key={id}
                              x={x}
                              y={y}
                              r={entityDiameter / 2}
                              isSelected={selectionsSet.has(id)}
                              color={color}
                              shape={shape}
                              name={name}
                            />
                          );
                        }
                      )}
                  </g>
                );
              })()}

              {(() => {
                return (
                  <g transform={mat}>
                    {[
                      ...(timelineState.mode === 'SEEKER'
                        ? combineEntityPlacementAtTime(
                            currentFormation,
                            timelineState.time
                          )
                        : localPlacements
                            .map(
                              ([key, placement]) =>
                                [
                                  key,
                                  {
                                    entity: getKV(performance.entities, key),
                                    placement,
                                  },
                                ] as [
                                  string,
                                  { entity: Entity; placement: EntityPlacement }
                                ]
                            )
                            .filter(([, { entity }]) => entity !== null)),
                    ]
                      .slice()
                      .reverse()
                      .filter(([, { entity }]) => !!entity)
                      .map(
                        ([
                          id,
                          {
                            // TODO: attempting to dereference color could
                            //   result in a crash!
                            entity: { color, name, shape },
                            placement: {
                              position: [x, y],
                            },
                          },
                        ]) => {
                          return (
                            <EntityObject
                              key={id}
                              isSelected={selectionsSet.has(id)}
                              x={x}
                              y={y}
                              r={entityDiameter / 2}
                              name={name}
                              shape={shape}
                              color={color}
                            />
                          );
                        }
                      )}
                  </g>
                );
              })()}

              {(() => {
                if (mouseState.type !== 'MOUSE_DOWN') return null;

                const { event } = mouseState;

                if (event.type !== 'BLANK_SPACE') return null;

                const { startPosition } = event;
                const endPosition = mousePositionRef.current;

                const width = Math.abs(startPosition[0] - endPosition[0]);
                const height = Math.abs(startPosition[1] - endPosition[1]);

                return (
                  <>
                    <rect
                      x={`${Math.min(startPosition[0], endPosition[0])}`}
                      y={`${Math.min(startPosition[1], endPosition[1])}`}
                      width={width}
                      height={height}
                      style={{
                        fill: '#5566ff',
                        fillOpacity: 0.5,
                        color: 'white',
                      }}
                    />
                    {/* <text
                      fill={'white'}
                      x={startPosition[0]}
                      y={startPosition[1]}
                    >{`${width / camera.zoom.linear / PIXElS_PER_METER}x${
                      height / camera.zoom.linear / PIXElS_PER_METER
                    }`}</text> */}
                  </>
                );
              })()}
            </>
          </SvgWrapper>
        ))()}
    </div>
  );
};

function snapToGrid(arg0: Vector2): any | globalThis.Vector2 {
  let x =
    (Math.round(arg0[0] / (defaultTickSpacing / 2)) * defaultTickSpacing) / 2;
  let y =
    (Math.round(arg0[1] / (defaultTickSpacing / 2)) * defaultTickSpacing) / 2;
  return [x, y];
}
